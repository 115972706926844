/**
 * Component to add the cookie consent to every page
 */
//import classes from '@styles/common/scss/_cookie.module.scss';
import classes from '@styles/scss/common/_cookie.module.scss';
import Link from 'next/link';
import { parseCookies } from 'nookies';
import React, { MouseEvent, useEffect, useState } from 'react';

function preventDefaultAnalytics(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
}

export default function Cookieconsent(): JSX.Element {
    const [showBanner, setBanner] = useState(false);
    function hideBanner() {
        const date = new Date();
        date.setTime(+date + 365 * 86400000);
        document.cookie = 'cookieconsent_status=dismiss;expires=' + date.toUTCString();
        setBanner(false);
    }
    useEffect(() => {
        const cookies = parseCookies();
        if (cookies.cookieconsent_status !== 'dismiss') {
            setBanner(true);
        }
    }, []);
    return (
        <div
            className={`${classes.cookieConsentBanner} ${showBanner ? undefined : classes.hide}`}
            id="cookieConsent">
            <p>
                {`We use cookies for a variety of site operations and for the purpose of continuous improvement. Our `}
                <Link prefetch={false} href="/tos#Cookie">
                    <a id="splash-cookieConsent" title="cookie policy">
                        cookie policy
                    </a>
                </Link>
                {` provides more information and choices about our cookie use. By using the website you agree to our use of cookies.`}
            </p>
            <a
                href="/"
                onClick={preventDefaultAnalytics}
                aria-label="analytics"
                title="analytics"
                role="button"
                data-event-category="User Action"
                data-event-action="Cookie-Confirm"
                data-event-label="PAGE">
                <div
                    className={classes.okButton}
                    onClick={() => hideBanner()}
                    onKeyDown={() => hideBanner()}
                    data-event-category="User Action"
                    data-event-action="Cookie-Confirm"
                    data-event-label="PAGE"
                    role="button"
                    tabIndex={0}
                    aria-label="accept button">
                    Got it!
                </div>
            </a>
        </div>
    );
}
