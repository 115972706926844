export default function svgLangTr(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
            <g fillRule="evenodd">
                <path fill="#f31930" d="M0 0h512v512H0z" />
                <path
                    d="M348.772 263.97c0 70.63-58.247 127.883-130.1 127.883S88.572 334.6 88.572 263.97s58.247-127.88 130.1-127.88 130.1 57.254 130.1 127.88z"
                    fill="#fff"
                />
                <path
                    d="M355.28 263.965c0 56.5-46.6 102.304-104.08 102.304s-104.08-45.805-104.08-102.306S193.718 161.66 251.2 161.66s104.08 45.804 104.08 102.304z"
                    fill="#f31830"
                />
                <path
                    d="M374.104 204.23l-1.05 47.272-44.103 11.973 43.475 15.482-1.05 43.35 28.352-33.853 42.842 14.863-24.783-36.332 30.242-36.125-46.414 12.798-27.512-39.428z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}
