export default function svgLangPt(): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            height="512"
            width="512"
            viewBox="0 0 512 512">
            <path fill="red" d="M204.79 0h307.26v512H204.79z" />
            <path fill="#060" d="M-.05 0h204.84v512H-.05z" />
            <g
                fill="#ff0"
                fillRule="evenodd"
                stroke="#000"
                strokeWidth=".573"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path
                    d="M293.78 326.588c-34.37-1.036-191.988-99.42-193.07-115.086l8.705-14.515c15.634 22.717 176.765 118.397 192.59 115.006l-8.225 14.59"
                    strokeWidth=".652"
                />
                <path
                    d="M107.583 195.015c-3.083 8.3 41.13 35.633 94.326 67.987 53.19 32.35 99.06 52.344 102.46 49.477.206-.37 1.67-2.893 1.536-2.873-.637.96-2.188 1.264-4.608.566-14.373-4.145-51.856-21.35-98.276-49.5-46.42-28.152-86.806-54.094-93.083-65.117-.436-.764-.747-2.158-.684-3.24l-.152-.004-1.337 2.337-.184.365zM294.59 327.043c-.584 1.057-1.67 1.093-3.735.866-12.857-1.425-51.87-20.397-98.033-48.03-53.715-32.155-98.077-61.453-93.264-69.11l1.31-2.315.257.08c-4.327 12.977 87.55 65.512 92.96 68.86 53.16 32.936 97.98 52.17 101.945 47.17l-1.443 2.483v-.004z"
                    strokeWidth=".652"
                />
                <path
                    d="M204.954 220.99c34.403-.27 76.857-4.7 101.29-14.438L300.978 198c-14.442 7.992-57.13 13.248-96.316 14.034-46.35-.426-79.064-4.742-95.456-15.74l-4.97 9.106c30.134 12.75 61.012 15.46 100.717 15.59"
                    strokeWidth=".652"
                />
                <path
                    d="M307.662 206.746c-.84 1.344-16.775 6.84-40.247 10.894-15.915 2.426-36.675 4.5-62.582 4.526-24.614.023-44.72-1.73-59.943-3.792-24.64-3.864-37.357-9.24-42.058-11.14.45-.893.74-1.52 1.172-2.355 13.534 5.387 26.31 8.636 41.278 10.94 15.122 2.047 34.986 3.824 59.478 3.8 25.787-.025 46.318-2.257 62.14-4.61 24.07-3.883 37.22-8.88 39.065-11.2l1.7 2.935h-.002zm-4.62-8.673c-2.61 2.095-15.608 6.705-38.48 10.357-15.262 2.188-34.67 4.146-59.465 4.17-23.556.023-42.802-1.565-57.44-3.697-23.227-3.033-35.59-8.505-40.045-10.1.443-.767.895-1.528 1.358-2.292 3.468 1.748 15.398 6.59 38.942 9.927 14.472 2.052 33.747 3.58 57.187 3.557 24.676-.025 43.862-2.02 59.03-4.197 22.99-3.163 35.36-9.042 37.236-10.514l1.676 2.79zM91.985 262.44c21.16 11.38 68.158 17.117 112.633 17.51 40.494.066 93.25-6.258 112.978-16.713l-.543-11.39c-6.172 9.644-62.707 18.896-112.875 18.517-50.168-.378-96.754-8.127-112.284-18.157l.09 10.23"
                    strokeWidth=".652"
                />
                <path
                    d="M318.675 260.8v2.718c-2.964 3.545-21.555 8.903-44.87 12.677-17.743 2.72-40.876 4.772-69.702 4.772-27.385 0-49.223-1.952-66.16-4.552-26.77-3.9-43.894-10.725-47.33-12.763l.014-3.17c10.325 6.866 38.3 11.888 47.692 13.425 16.824 2.583 38.533 4.52 65.78 4.52 28.69 0 51.695-2.038 69.327-4.74 16.727-2.416 40.555-8.7 45.246-12.884zm.012-9.66v2.716c-2.964 3.543-21.556 8.9-44.87 12.674-17.743 2.72-40.877 4.773-69.702 4.773-27.386 0-49.224-1.95-66.16-4.553-26.77-3.896-43.894-10.723-47.33-12.76l.014-3.17c10.323 6.863 38.296 11.886 47.69 13.42 16.825 2.586 38.534 4.525 65.783 4.525 28.69 0 51.694-2.04 69.326-4.744 16.725-2.414 40.554-8.7 45.245-12.882v.002zm-114.165 73.49c-48.665-.29-90.363-13.265-99.17-15.41l6.422 10.052c15.554 6.543 56.234 16.293 93.215 15.213 36.98-1.078 69.3-3.943 92.076-15.03l6.583-10.418c-15.52 7.31-68.345 15.52-99.13 15.594"
                    strokeWidth=".652"
                />
                <path
                    d="M299.538 317.12a152.554 152.554 0 0 1-2.954 4.358c-10.743 3.788-27.67 7.765-34.813 8.925-14.59 3.007-37.163 5.227-57.2 5.237-43.11-.63-78.397-9.07-95-16.27l-1.34-2.302.22-.346 2.273.88c29.523 10.563 62.68 14.777 94.092 15.534 19.954.07 39.93-2.287 56.083-5.176 24.77-4.963 34.786-8.704 37.86-10.4l.78-.438zm5.708-9.414c.025.03.05.057.075.088a301.968 301.968 0 0 1-2.23 3.712c-5.73 2.048-21.28 6.598-43.986 9.773-14.96 2.036-24.26 4.01-54.02 4.59-55.77-1.42-91.876-12.326-100.454-15.015l-1.272-2.44c32.32 8.438 65.333 14.328 101.726 14.93 27.228-.58 38.812-2.585 53.65-4.608 26.476-4.117 39.816-8.476 43.82-9.735a3.058 3.058 0 0 0-.176-.226l2.87-1.073v.002z"
                    strokeWidth=".626"
                />
                <path
                    d="M305.83 253.45c.158 32.015-16.225 60.743-29.42 73.416-18.67 17.93-43.422 29.464-72.328 29.996-32.28.593-62.715-20.45-70.878-29.695-15.96-18.074-28.955-41.025-29.372-71.957 1.977-34.94 15.694-59.278 35.576-75.968 19.883-16.69 46.357-24.818 68.405-24.237 25.438.67 55.153 13.15 75.686 37.925 13.45 16.23 19.275 33.846 22.33 60.52zM204.356 143.837c61.992 0 112.977 50.446 112.977 112.302 0 61.853-50.985 112.302-112.977 112.302-61.992 0-112.554-50.447-112.554-112.302 0-61.856 50.562-112.302 112.554-112.302"
                    strokeWidth=".652"
                />
                <path
                    d="M204.66 143.45c62.046 0 112.65 50.613 112.65 112.666 0 62.052-50.605 112.663-112.65 112.663-62.05 0-112.654-50.61-112.654-112.665 0-62.053 50.606-112.665 112.653-112.665zM94.484 256.117c0 60.6 49.762 110.185 110.174 110.185 60.41 0 110.173-49.585 110.173-110.184 0-60.6-49.762-110.186-110.173-110.186-60.413 0-110.175 49.588-110.175 110.186z"
                    strokeWidth=".652"
                />
                <path
                    d="M204.75 152.815c56.582 0 103.188 46.445 103.188 103.2 0 56.758-46.607 103.2-103.188 103.2-56.582 0-103.19-46.442-103.19-103.2 0-56.755 46.61-103.2 103.19-103.2zm-100.71 103.2c0 55.393 45.486 100.72 100.71 100.72 55.223 0 100.71-45.327 100.71-100.72 0-55.394-45.486-100.722-100.71-100.722S104.04 200.62 104.04 256.015z"
                    strokeWidth=".652"
                />
                <path d="M209.29 142.996h-9.655l.01 226.372h9.69z" strokeWidth=".652" />
                <path
                    d="M208.322 141.706h2.458l.02 228.977h-2.46l-.017-228.977zm-9.58.002h2.475l.004 228.977h-2.477V141.708z"
                    strokeWidth=".652"
                />
                <path
                    d="M317.39 260.477v-8.37l-6.816-6.347-38.684-10.24-55.75-5.69-67.13 3.414-47.787 11.378-9.648 7.145v8.37l24.44-10.964 58.026-9.102h55.753l40.96 4.553 28.445 6.827z"
                    strokeWidth=".652"
                />
                <path
                    d="M204.704 238.672c26.605-.05 52.418 2.52 72.892 6.507 21.127 4.222 35.996 9.505 41.08 15.44l-.006 2.937c-6.13-7.384-26.138-12.798-41.55-15.893-20.318-3.952-45.958-6.504-72.416-6.455-27.92.05-53.916 2.695-73.937 6.602C114.7 251 93.277 257.332 90.6 263.58v-3.06c1.47-4.323 17.424-10.784 39.805-15.265 20.172-3.933 46.217-6.532 74.3-6.583zm.012-9.663c26.604-.05 52.417 2.52 72.89 6.506 21.13 4.225 35.997 9.507 41.08 15.44l-.005 2.94c-6.13-7.384-26.136-12.798-41.547-15.892-20.32-3.954-45.96-6.506-72.416-6.455-27.92.05-53.79 2.695-73.814 6.6-15.503 2.94-37.804 9.523-40.29 15.77v-3.06c1.468-4.28 17.745-10.97 39.804-15.268 20.172-3.933 46.217-6.53 74.3-6.583zm-.546-49.287c41.925-.21 78.495 5.863 95.226 14.433l6.103 10.558c-14.544-7.837-53.997-15.986-101.27-14.768-38.52.237-79.676 4.24-100.325 15.258l7.286-12.185c16.948-8.79 56.915-13.25 92.98-13.295"
                    strokeWidth=".652"
                />
                <path
                    d="M204.727 188.44c23.913-.065 47.02 1.284 65.395 4.6 17.113 3.185 33.433 7.965 35.767 10.536l1.807 3.197c-5.675-3.706-19.798-7.832-37.932-11.27-18.21-3.424-41.302-4.558-65.08-4.495-26.99-.093-47.96 1.332-65.957 4.47-19.034 3.56-32.24 8.65-35.496 11.066l1.773-3.38c6.33-3.23 16.372-7.12 33.25-10.16 18.61-3.4 39.802-4.42 66.472-4.565h-.002zm-.01-9.655c22.884-.06 45.48 1.216 63.092 4.382 13.89 2.704 27.626 6.926 32.657 10.682l2.646 4.203c-4.5-5-21.432-9.74-36.38-12.393-17.477-3.013-39.134-4.206-62.018-4.397-24.016.067-46.21 1.538-63.433 4.674-16.43 3.127-27.034 6.83-31.52 9.72l2.327-3.512c6.19-3.26 16.192-6.253 28.79-8.7 17.35-3.16 39.68-4.592 63.836-4.66zm55.966 124.16c-20.728-3.87-41.494-4.432-55.998-4.26-69.868.818-92.438 14.345-95.192 18.44l-5.222-8.51c17.79-12.894 55.836-20.125 100.794-19.385 23.345.382 43.49 1.932 60.438 5.215l-4.82 8.502"
                    strokeWidth=".652"
                />
                <path
                    d="M204.283 297.483c19.436.29 38.513 1.093 56.927 4.527l-1.334 2.354c-17.102-3.157-35.334-4.365-55.527-4.267-25.787-.2-51.867 2.207-74.573 8.723-7.164 1.994-19.025 6.6-20.234 10.406l-1.327-2.187c.383-2.25 7.55-6.92 20.95-10.686 26.01-7.448 50.335-8.703 75.116-8.874v.004zm.882-9.78c20.137.376 40.932 1.307 61.144 5.31l-1.39 2.45c-18.253-3.622-35.69-4.83-59.637-5.198-25.867.047-53.306 1.89-78.254 9.144-8.056 2.344-21.957 7.417-22.42 11.433l-1.326-2.35c.302-3.648 12.335-8.406 23.162-11.556 25.14-7.31 52.65-9.188 78.72-9.235z"
                    strokeWidth=".626"
                />
                <path
                    d="M304.408 309.908l-8.396 13.026-24.122-21.423-62.578-42.096-70.542-38.684-36.625-12.528 7.805-14.473 2.65-1.447 22.757 5.69 75.093 38.684 43.235 27.306 36.41 26.17 14.79 17.066z"
                    strokeWidth=".652"
                />
                <path
                    d="M100.825 208.543c6.424-4.357 53.634 16.674 103.032 46.46 49.266 29.872 96.343 63.626 92.094 69.943l-1.396 2.198-.64.506c.136-.098.844-.964-.07-3.306-2.1-6.907-35.494-33.554-90.902-67.01-54.015-32.21-99.033-51.622-103.534-46.073l1.42-2.716h-.002zm205.3 101.18c4.063-8.024-39.726-41.01-94.016-73.128-55.54-31.478-95.57-50.006-102.88-44.48l-1.625 2.956c-.014.165.06-.2.403-.465 1.328-1.16 3.532-1.08 4.526-1.1 12.59.19 48.546 16.736 98.994 45.657 22.104 12.875 93.378 58.587 93.106 71.44.02 1.106.092 1.334-.324 1.88l1.813-2.757v-.002z"
                    strokeWidth=".652"
                />
            </g>
            <g transform="translate(-68.306 28.444) scale(1.13778)">
                <path
                    d="M180.6 211.01c0 16.27 6.663 30.987 17.457 41.742 10.815 10.778 25.512 17.58 41.81 17.58 16.38 0 31.246-6.654 42.015-17.39 10.77-10.735 17.443-25.552 17.446-41.88h-.002v-79.19l-118.74-.14.012 79.278h.002z"
                    fill="#fff"
                    stroke="#000"
                    strokeWidth=".67"
                />
                <path
                    d="M182.82 211.12v.045c0 15.557 6.44 29.724 16.775 40.01 10.354 10.304 24.614 16.71 40.214 16.71 15.68 0 29.91-6.36 40.22-16.625 10.31-10.265 16.697-24.433 16.7-40.044h-.003V134.39l-113.84-.02-.07 76.75m91.022-53.748l.003 48.89-.04 5.173c0 1.36-.082 2.912-.24 4.233-.926 7.73-4.48 14.467-9.746 19.708-6.164 6.136-14.67 9.942-24.047 9.942-9.326 0-17.638-3.938-23.828-10.1-6.35-6.32-10.03-14.986-10.03-23.947l-.013-54.02 67.94.12v.003z"
                    fill="red"
                    stroke="#000"
                    strokeWidth=".507"
                />
                <g id="e">
                    <g id="d" fill="#ff0" stroke="#000" strokeWidth=".5">
                        <path
                            d="M190.19 154.43c.135-5.52 4.052-6.828 4.08-6.847.03-.02 4.232 1.407 4.218 6.898l-8.298-.05"
                            stroke="none"
                        />
                        <path d="M186.81 147.69l-.682 6.345 4.14.01c.04-5.25 3.975-6.124 4.07-6.104.09-.003 3.99 1.16 4.093 6.105h4.153l-.75-6.394-15.022.04zm-.96 6.37h16.946c.357 0 .65.353.65.784 0 .43-.293.78-.65.78H185.85c-.357 0-.65-.35-.65-.78 0-.43.293-.784.65-.784z" />
                        <path d="M192.01 154.03c.018-3.313 2.262-4.25 2.274-4.248 0 0 2.342.966 2.36 4.248h-4.634m-5.8-8.98h16.245c.342 0 .623.318.623.705 0 .387-.28.704-.623.704H186.21c-.342 0-.623-.317-.623-.706 0-.387.28-.705.623-.705zm.34 1.42h15.538c.327 0 .595.317.595.704 0 .388-.268.704-.595.704H186.55c-.327 0-.595-.316-.595-.704 0-.387.268-.704.595-.704zm5.02-10.59l1.227.002v.87h.895v-.89l1.257.005v.887h.895v-.89h1.258l-.002 2.01c0 .317-.253.52-.55.52h-4.41c-.295 0-.57-.236-.57-.525l-.003-1.99zm4.62 2.69l.277 6.45-4.303-.015.285-6.452 3.74.017" />
                        <path id="a" d="M190.94 141.56l.13 3.478h-4.124l.116-3.478h3.88-.002z" />
                        <use height="100%" width="100%" xlinkHref="#a" x="10.609" />
                        <path
                            id="b"
                            d="M186.3 139.04l1.2.003v.872h.877v-.892l1.23.004v.89h.88v-.894l1.23.002-.003 2.012c0 .314-.25.518-.536.518h-4.317c-.29 0-.557-.235-.557-.525l-.003-1.99z"
                        />
                        <use height="100%" width="100%" xlinkHref="#b" x="10.609" />
                        <path
                            d="M193.9 140.61c-.026-.627.877-.634.866 0v1.536h-.866v-1.536"
                            fill="#000"
                            stroke="none"
                        />
                        <path
                            id="c"
                            d="M188.57 142.84c-.003-.606.837-.618.826 0v1.187h-.826v-1.187"
                            fill="#000"
                            stroke="none"
                        />
                        <use height="100%" width="100%" xlinkHref="#c" x="10.641" />
                    </g>
                    <use height="100%" width="100%" xlinkHref="#d" y="46.32" />
                    <use
                        height="100%"
                        width="100%"
                        xlinkHref="#d"
                        transform="rotate(-45.202 312.766 180.004)"
                    />
                </g>
                <use height="100%" width="100%" xlinkHref="#d" x="45.714" />
                <use
                    height="100%"
                    width="100%"
                    xlinkHref="#e"
                    transform="matrix(-1 0 0 1 479.792 0)"
                />
                <g id="f" fill="#fff">
                    <path
                        d="M232.636 202.406v.005a8.34 8.34 0 0 0 2.212 5.69c1.365 1.468 3.245 2.38 5.302 2.38 2.067 0 3.944-.906 5.303-2.366 1.358-1.46 2.202-3.472 2.202-5.693v-10.767l-14.992-.013-.028 10.765"
                        fill="#039"
                    />
                    <circle cx="236.074" cy="195.735" r="1.486" />
                    <circle cx="244.392" cy="195.742" r="1.486" />
                    <circle cx="240.225" cy="199.735" r="1.486" />
                    <circle cx="236.074" cy="203.916" r="1.486" />
                    <circle cx="244.383" cy="203.905" r="1.486" />
                </g>
                <use height="100%" width="100%" xlinkHref="#f" y="-26.016" />
                <use height="100%" width="100%" xlinkHref="#f" x="-20.799" />
                <use height="100%" width="100%" xlinkHref="#f" x="20.745" />
                <use height="100%" width="100%" xlinkHref="#f" y="25.784" />
            </g>
        </svg>
    );
}
