export default function svgLangRo(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
            <g fillRule="evenodd" strokeWidth="1pt">
                <path fill="#00319c" d="M0 0h170.666v512H0z" />
                <path fill="#ffde00" d="M170.666 0h170.666v512H170.666z" />
                <path fill="#de2110" d="M341.332 0h170.665v512H341.332z" />
            </g>
        </svg>
    );
}
