import { useEffect, useState } from 'react';

export function windowWidth(): number {
    const [windowSize, setWindowSize] = useState({
        width: 0
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // Handler to call on window resize
            const handleResize = () => {
                setWindowSize({
                    width: window.innerWidth
                });
            };

            window.addEventListener('resize', handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize.width;
}
