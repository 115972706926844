import LangDropdown from '@components/partials/navigation/langDropdown';
import Hamburger from '@components/partials/svg/hamburgerIcon';
import LogoLarge from '@components/partials/svg/logoLarge';
import LogoMascot from '@components/partials/svg/logoMascot';
import LogoSmall from '@components/partials/svg/logoSmall';
import styles from '@styles/scss/common/_topbar.module.scss';
import { loadKraken } from 'lib/helper/loadKraken';
import { windowWidth } from 'lib/helper/windowWidth';
import { ApiLanguageProps } from 'lib/types/item/apiLanguageType';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

export default function TopBar({
    categoryList,
    homeSlug,
    languageList,
    is404Page,
    isHomePage
}: {
    categoryList: any;
    homeSlug?: string;
    languageList?: ApiLanguageProps | undefined;
    is404Page?: boolean;
    isHomePage: boolean;
}): JSX.Element {
    const categories: any = [];
    Object.keys(categoryList)
        .map((categoryName) => categoryList[categoryName])
        .map((categoryData) => {
            if (categoryData.categoryActive) {
                if (categoryData.categorySlug !== 'submit-your-site' && isHomePage == false) {
                    categories.push(categoryData);
                } else if (isHomePage == true) {
                    categories.push(categoryData);
                }
            }
        });
    const [menuHam, setMenuHam] = useState(false);
    const [menuLangMobile, setMenuLangMobile] = useState(false);
    const [menuLangDesktop, setMenuLangDesktop] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const [reducedHeight, setReducedHeight] = useState('');
    const width = windowWidth();
    const router = useRouter();
    const langMenuMobileRef = useRef<HTMLDivElement>(null);
    const langMenuDesktopRef = useRef<HTMLDivElement>(null);
    const hamMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollPos > 70) {
            setReducedHeight(styles.reduced);
        } else {
            setReducedHeight('');
        }
    }, [width, scrollPos]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                langMenuMobileRef.current &&
                !langMenuMobileRef.current.contains(event.target as HTMLElement)
            ) {
                setMenuLangMobile(false);
            }
            if (
                langMenuDesktopRef.current &&
                !langMenuDesktopRef.current.contains(event.target as HTMLElement)
            ) {
                setMenuLangDesktop(false);
            }
            if (hamMenuRef.current && !hamMenuRef.current.contains(event.target as HTMLElement)) {
                setMenuHam(false);
            }
        };
        const handleScrollPos = () => {
            setScrollPos(document.documentElement.scrollTop);
        };
        document.addEventListener('click', handleClickOutside);
        window.addEventListener('scroll', handleScrollPos);
        return () => {
            window.removeEventListener('scroll', handleScrollPos);
            document.removeEventListener('click', handleClickOutside);
        };
    });

    const toggleLangMobile = () => {
        setMenuLangMobile(!menuLangMobile);
        setMenuHam(false);
    };
    const toggleLangDesktop = () => {
        setMenuLangDesktop(!menuLangDesktop);
        setMenuHam(false);
    };
    const toggleHam = () => {
        setMenuLangMobile(false);
        setMenuLangDesktop(false);
        setMenuHam(!menuHam);
    };

    const goToCategory = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            router.push('/' + id);
        }
    };

    useEffect(() => {
        loadKraken();
    }, [router.asPath]);

    return (
        <div className={styles.container}>
            <div className={`${styles.fullWidth} ${reducedHeight}`}></div>
            <div className={`${styles.menu} ${reducedHeight}`}>
                <div className={`${styles.logoLeft} ${reducedHeight}`}>
                    <Link prefetch={false} href={homeSlug && homeSlug !== '/en' ? homeSlug : '/'}>
                        <a>
                            <div className={styles.logoSmall}>
                                <LogoSmall />
                            </div>
                            <div className={styles.logoLarge}>
                                <LogoLarge />
                            </div>
                        </a>
                    </Link>
                </div>
                <div className={`${styles.menuButtons} ${reducedHeight}`}>
                    <div
                        className={styles.langButtonMobile}
                        role="button"
                        aria-hidden="true"
                        ref={langMenuMobileRef}
                        onClick={() => toggleLangMobile()}
                        onKeyDown={() => toggleLangMobile()}>
                        <LangDropdown
                            isLang={menuLangMobile}
                            homeSlug={homeSlug}
                            languageList={languageList}
                            is404Page={is404Page}
                        />
                    </div>
                    <div
                        className={styles.hamburgerButton}
                        role="button"
                        aria-hidden="true"
                        ref={hamMenuRef}
                        onClick={() => toggleHam()}
                        onKeyDown={() => toggleHam()}>
                        <button>
                            <span></span>
                        </button>
                        {menuHam && (
                            <>
                                <div className={styles.hamburgerDropDown}>
                                    {categories.map((category: any) => (
                                        <div
                                            className={styles.hamburgerItem}
                                            onClick={() => goToCategory(category.categorySlug)}
                                            onKeyDown={() => goToCategory(category.categorySlug)}
                                            key={category.categoryName}
                                            role="button"
                                            aria-hidden="true">
                                            <div>{category.categoryName}</div>
                                            <div>({category.activeSites.length})</div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                    <div
                        className={styles.langButtonDesktop + ' pointer'}
                        role="button"
                        aria-hidden="true"
                        ref={langMenuDesktopRef}
                        onClick={() => toggleLangDesktop()}
                        onKeyDown={() => toggleLangDesktop()}>
                        <LangDropdown
                            isLang={menuLangDesktop}
                            homeSlug={homeSlug}
                            languageList={languageList}
                            is404Page={is404Page}
                        />
                    </div>
                </div>
                <div className={`${styles.logoRight} ${reducedHeight}`}>
                    <LogoMascot />
                </div>
            </div>
        </div>
    );
}
