export default function EmailIcon(): JSX.Element {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 68 68"
            xmlSpace="preserve">
            <g>
                <path
                    style={{ fill: '#E06939' }}
                    d="M41.9,10.6H26.1c-0.4,0-0.8-0.3-0.9-0.7s0-0.8,0.3-1.1l7.9-6.6c0.4-0.3,0.9-0.3,1.3,0l7.9,6.6
		c0.3,0.3,0.4,0.7,0.3,1.1C42.7,10.3,42.3,10.6,41.9,10.6z M28.9,8.6h10.3L34,4.3L28.9,8.6z"
                />
            </g>
            <g>
                <path
                    style={{ fill: '#E06939' }}
                    d="M34,55.6c-0.2,0-0.5-0.1-0.6-0.2L2.5,29.6c-0.2-0.2-0.4-0.5-0.4-0.8s0.1-0.6,0.4-0.8l10.7-8.9l1.3,1.5
		l-9.8,8.1L34,53.3l29.3-24.5l-9.8-8.1l1.3-1.5L65.5,28c0.2,0.2,0.4,0.5,0.4,0.8s-0.1,0.6-0.4,0.8L34.6,55.4
		C34.5,55.5,34.2,55.6,34,55.6z"
                />
            </g>
            <g>
                <path
                    style={{ fill: '#E06939' }}
                    d="M65,66H3c-0.6,0-1-0.4-1-1V28.8c0-0.6,0.4-1,1-1h0.1c0.2,0,0.5,0.1,0.6,0.2L34,53.3L64.3,28
		c0.6-0.5,1.7,0,1.7,0.8V65C66,65.6,65.6,66,65,66z M4,64h60V30.9L34.6,55.4c-0.4,0.3-0.9,0.3-1.3,0L4,30.9V64z"
                />
            </g>
            <g>
                <path
                    style={{ fill: '#E06939' }}
                    d="M64.9,66c-0.2,0-0.5-0.1-0.6-0.2L42.6,47.7c-0.4-0.4-0.5-1-0.1-1.4s1-0.5,1.4-0.1l21.7,18.1
		c0.4,0.4,0.5,1,0.1,1.4C65.5,65.9,65.2,66,64.9,66z"
                />
            </g>
            <g>
                <path
                    style={{ fill: '#E06939' }}
                    d="M3.1,66c-0.3,0-0.6-0.1-0.8-0.4c-0.3-0.4-0.3-1,0.2-1.4l21.7-18.1c0.4-0.4,1.1-0.3,1.4,0.1
		c0.4,0.4,0.3,1.1-0.1,1.4L3.7,65.8C3.6,65.9,3.3,66,3.1,66z"
                />
            </g>
            <g>
                <path
                    style={{ fill: '#E06939' }}
                    d="M34,55.6c-0.2,0-0.5-0.1-0.6-0.2L13.2,38.6c-0.2-0.2-0.4-0.5-0.4-0.8V9.6c0-0.6,0.4-1,1-1h40.4
		c0.6,0,1,0.4,1,1v28.2c0,0.3-0.1,0.6-0.4,0.8L34.6,55.4C34.5,55.5,34.2,55.6,34,55.6z M14.8,37.3l19.2,16l19.2-16V10.6H14.8V37.3z"
                />
            </g>
            <g>
                <path
                    style={{ fill: '#E06939' }}
                    d="M48.4,16.2H19.6c-0.6,0-1-0.4-1-1s0.4-1,1-1h28.8c0.6,0,1,0.4,1,1S49,16.2,48.4,16.2z"
                />
            </g>
            <g>
                <path
                    style={{ fill: '#E06939' }}
                    d="M48.4,23.3H19.6c-0.6,0-1-0.4-1-1s0.4-1,1-1h28.8c0.6,0,1,0.4,1,1S49,23.3,48.4,23.3z"
                />
            </g>
            <g>
                <path
                    style={{ fill: '#E06939' }}
                    d="M48.4,30.4H19.6c-0.6,0-1-0.4-1-1s0.4-1,1-1h28.8c0.6,0,1,0.4,1,1S49,30.4,48.4,30.4z"
                />
            </g>
            <g>
                <path
                    style={{ fill: '#E06939' }}
                    d="M48.4,37.4H19.6c-0.6,0-1-0.4-1-1s0.4-1,1-1h28.8c0.6,0,1,0.4,1,1S49,37.4,48.4,37.4z"
                />
            </g>
        </svg>
    );
}
