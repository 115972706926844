export default function svgLogoMobile(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="762" height="116" viewBox="0 0 762 116">
            <path d="M711.08 5.458c12.71 0 24.206 5.152 32.542 13.49 8.334 8.334 13.49 19.808 13.49 32.518s-5.155 24.247-13.49 32.54c-8.335 8.335-19.832 13.488-32.54 13.488s-24.25-5.152-32.563-13.488c-8.315-8.313-13.47-19.788-13.47-32.52 0-12.707 5.154-24.203 13.47-32.54 8.312-8.336 19.852-13.51 32.56-13.51v.022zm0 10.826c-19.44 0-35.203 15.76-35.203 35.203 0 19.442 15.763 35.203 35.204 35.203s35.204-15.76 35.204-35.203c0-19.442-15.762-35.203-35.203-35.203z" />
            <path
                fill="#F4CEAB"
                d="M715.844 35.985c.174 2.73.65 7.274 1.797 8.876 1.67 2.34-.13 3.38-.13 3.38l-2.12 1.515-5.196 2.338s-4.546 1.148-5.76-1.083l-.562-.974c-.91-.346-1.776-1.125-2.49-2.425-2.316-5.26-4.07-6.818-4.503-8.443-.478-1.796-.326-1.883.085-3.377-2.056-1.56-2.013-8.66-2.013-8.66-1.213.28 3.507-1.58 4.72-.368 1.212 1.212 3.507-1.407 5.542-2.165 2.057-.714 6.126 2.598 6.062 3.096-.107.498.65 4.59.478 4.113-.175-.498-.434.65 0 0 .432-.65 5.736-3.333 5.237 1.3-.15 1.515-.648 2.38-1.19 2.878h.044z"
            />
            <path d="M748.146 19.727c1.082-.585 2.75 2.987 3.81 1.948.564-.52 6.713 2.598 3.205 3.247 0 0-2.164.65-2.706.65-1.256 0-5.195-4.115-4.287-5.846h-.02z" />
            <path d="M746.154 7.927l.107-.585c.65-1.428 2.643-1.234 3.683 0 .713.866.93 3.357 2.316 3.313.323-.39.517-1.233.8-1.818 2.598-5.412 4.33-3.766 4.98-3.897 2.315-.54 1.6.694 2.164 1.212.108.087 1.363.433.433 2.512.65.54 1.082.476.93 1.904-.215 1.905-2.705 3.032-3.16 6.28-.302 2.315-.562 3.57-1.73 4.85-1.148 1.297-1.84.323-1.992 3.138 0 .28 0 .152-.044.433-.43 1.73-5.8-2.598-5.692-3.725.28-.65.736-.542.93-1.342.306-1.3-.908-1.667-.93-5.803 0-1.99-.304-2.382-1.08-3.507-.564-.866-1.625-1.905-1.733-2.944l.02-.023z" />
            <path d="M745.852 7.884l.086-.585v-.023l.044-.043c.13-.28.303-.52.52-.693.368-.303.8-.432 1.256-.476.433 0 .91.065 1.34.26.393.218.78.477 1.085.824.28.346.475.867.692 1.43.324.822.65 1.688 1.233 1.774.13-.217.217-.52.37-.867.085-.217.17-.52.28-.737 2.273-4.72 4.005-4.374 4.936-4.157H758c1.946-.432 2.098.218 2.27.867.044.217.088.346.22.433.302.173 1.34.715.583 2.664l.108.087c.542.39.867.606.714 1.948-.106.91-.648 1.624-1.298 2.448-.757.974-1.623 2.1-1.862 3.832-.15 1.17-.303 2.08-.563 2.88-.26.8-.648 1.473-1.232 2.165-.434.433-.802.65-1.083.78-.477.26-.78.432-.865 2.12v.282l-.044.173v.044c-.086.367-.324.584-.65.648-.303.064-.69 0-1.124-.217-.39-.13-.824-.367-1.257-.65-1.646-1.08-3.377-2.857-3.313-3.593v-.087c.173-.368.367-.542.563-.692.173-.13.326-.26.39-.584.108-.476 0-.822-.218-1.3-.28-.822-.713-1.99-.713-4.438 0-1.84-.283-2.208-.952-3.247l-.044-.085-.478-.65c-.563-.713-1.21-1.56-1.298-2.424v-.106zm.735-.434l-.086.5c.087.692.65 1.428 1.17 2.1l.476.648.064.107c.78 1.125 1.083 1.56 1.083 3.594 0 2.338.433 3.42.693 4.178.215.65.368 1.083.215 1.732-.128.52-.344.713-.582.91-.11.107-.22.215-.325.432.042.542 1.58 1.95 3.03 2.923.39.26.78.476 1.126.65.324.107.584.15.757.13.063 0 .13-.065.15-.175v-.347c.108-2.078.52-2.295 1.192-2.663.26-.13.562-.304.907-.65.542-.65.866-1.21 1.082-1.948.26-.737.39-1.602.54-2.75.26-1.904 1.19-3.117 1.993-4.113.585-.78 1.082-1.43 1.17-2.166.106-.954-.088-1.083-.434-1.342l-.37-.282-.174-.15.11-.218c.69-1.58 0-1.948-.218-2.057-.065 0-.087-.043-.13-.086-.218-.218-.305-.5-.368-.76-.087-.367-.218-.713-1.518-.432-.173.065-.345 0-.562 0-.758-.173-2.166-.476-4.222 3.767l-.26.65c-.174.476-.325.91-.585 1.19l-.086.108h-.153c-1.146.065-1.58-1.082-2.013-2.165-.173-.52-.367-.996-.584-1.255-.218-.304-.542-.52-.866-.65-.324-.172-.692-.216-1.04-.216-.303 0-.604.108-.865.303-.13.13-.217.282-.325.433v.067h.007zm13.856-1.515c.044 0 0 0 0-.043v.043z" />
            <path
                fill="#FFF"
                d="M751.72 11.174c-.108.867-.347 1.148-.347 2.165.52-1.126.477-2.383 1.56-2.555-.152-.563-.37-.26.433-1.84.822-1.733 2.23-4.158 4.113-3.726-.304.432-1.084.823-2.08 2.38-.563.91-1.082 2.555-1.73 3.074l.866.542c-.218-.953-.347-.865.43-2.382.652-1.255 1.907-2.966 3.034-3.57.91-.477 1.73-.283 1.818.865-1.385.865-1.083.26-2.6 2.27-1.298 1.798-.866 2.21-1.947 3.293.434.217.434.39.716.648-.218-2.38 3.203-6.818 4.48-5.735.39.476.37 1.342-.042 1.948-.347.563-.542.217-1.344 1.3-.78 1.08-.866 1.795-1.363 2.77-.39.78-.086.217-.692.542.433.26.498.368.715.714.173-1.408.475-2.21 1.255-3.51.477-.734 1.948-2.336 2.382-.604.434 1.732-1.45 2.533-2.445 4.655-.434.867-.348 1.17-.823 1.213h-.065c.433 2.64-.866 6.41-2.902 6.452-1.082 0-1.516-.65-2.166-1.147.434.974.932.996 1.776 1.558 0 .368-.217 1.517-.52 1.732-.282.282-.432.217-1.234-.216-.435-.217-.867-.5-1.3-.65.715.954 2.382 1.04 2.6 1.69-.738.432-2.47-.78-3.1-1.235l-1.19-.974c-.432-.433-.93-.714-.69-1.473.367.174.345.326.735.607l-.152-.325s0-.064-.043-.086c-.108-.217-.108-.174-.217-.345.304-.562.347-.562.542-1.363.735.173.648.476 1.688.605-.324-.26-.757-.303-1.17-.648-.367-.347-.584-.65-.8-1.083-1.73-3.527.498-4.98-2.25-8.55-.368-.52-1.257-1.58-1.214-2.34.086-1.168 2.662-2.208 3.895 1.04.433 1.125.52 1.516 1.363 2.295h.022z"
            />
            <path d="M674.968 95.242c.152.606.952 3.897 1.256 4.698.347.867 2.51 1.732 3.162 2.166 1.84 1.233 1.622 1.86 2.944 1.516.303-.108-.65-.433-.174-.932.78-.735-1.667-5.412-2.058-6.538-.648-1.73-1.08-1.168-3.072-1.795-1.213-.434-1.3.648-2.08.865l.022.02z" />
            <path d="M663.537 97.342l-.476.326c-.953 1.297.065 3.55 1.56 4.242 1.082.543 3.355-.217 3.897 1.407-.174.542-.823 1.082-1.213 1.646-3.572 5.197-1.406 6.605-1.256 7.36.542 2.902 1.3 1.604 2.014 2.058.108.065.954 1.386 2.383-.52.736.564.866 1.083 2.035.325 1.56-.996 1.473-4.33 4.113-6.126 1.884-1.3 2.858-2.1 3.464-3.897.606-1.862-.52-2.316 1.86-3.595.22-.107.13-.043.327-.216 1.363-1.17-4.764-5.673-5.672-5.13-.478.562-.153 1.08-.76 1.6-.996.866-1.817-.432-5.41 1.214-1.776.78-2.21.584-3.552.15-.975-.324-2.34-1.082-3.29-.8l-.023-.044z" />
            <path d="M663.666 97.624l-.368.28-.13.218c-.215.498-.215 1.125 0 1.732.26.584.65 1.167 1.19 1.515.132.107.262.216.434.26.282.15.78.173 1.3.217 1.082 0 2.316.085 2.75 1.34v.22c-.175.388-.5.757-.824 1.125l-.455.606c-2.814 4.07-1.95 5.67-1.45 6.494.13.217.218.433.26.605.326 1.73.65 1.73 1.02 1.666.28 0 .54-.042.866.153l.106.107.217.217c.26.217.778.324 1.58-.758l.216-.216.217.15c.174.086.304.216.433.304.346.28.542.434 1.3 0 .562-.433.93-1.255 1.298-2.21.605-1.32 1.233-2.835 2.814-3.918.93-.65 1.624-1.168 2.165-1.73.52-.543.91-1.17 1.19-2.037.218-.562.218-.973.218-1.34 0-.91 0-1.45 1.797-2.384l.15-.106.087-.086c.13-.108.13-.26.065-.434-.108-.324-.368-.714-.736-1.082-.39-.433-.867-.865-1.365-1.3-1.3-1.08-2.77-1.947-3.205-1.84-.107.217-.15.433-.217.585-.044.347-.108.65-.54 1.04-.52.432-.955.432-1.624.432-.76-.045-1.887-.065-3.898.865-1.818.78-2.316.606-3.637.174l-.11-.044-.8-.28c-.78-.327-1.646-.65-2.252-.52l-.067-.022zm-.778-.216l.476-.347h.044l.044-.042c.822-.26 1.84.15 2.728.52.26.087.52.217.757.282h.13c1.17.43 1.603.56 3.16-.132 2.167-.973 3.377-.952 4.202-.91.518 0 .864 0 1.19-.28.258-.217.324-.434.366-.65.044-.302.108-.583.368-.91l.044-.042.043-.042c.586-.327 2.426.648 3.984 1.883.54.434 1.04.866 1.43 1.343.433.432.735.91.865 1.298.174.434.108.866-.217 1.148l-.173.15c-.044 0-.065 0-.087.044l-.13.043c-1.43.757-1.45 1.17-1.474 1.84 0 .39 0 .867-.218 1.517-.325.974-.758 1.666-1.342 2.295-.585.65-1.3 1.17-2.273 1.818-1.406.953-1.992 2.383-2.51 3.68-.477 1.04-.867 1.95-1.625 2.47-1.126.735-1.472.432-2.056 0 0-.065-.066-.087-.108-.13-1.04 1.233-1.86.953-2.316.605l-.28-.262c-.132-.085-.282-.085-.435-.063-.648.063-1.298.108-1.73-2.208 0-.087-.088-.216-.174-.39-.54-.952-1.58-2.814 1.472-7.21l.543-.65c.216-.28.498-.562.605-.8-.324-.757-1.256-.8-2.122-.823-.562-.042-1.124-.064-1.558-.26l-.542-.303c-.606-.434-1.082-1.083-1.385-1.82-.26-.757-.305-1.56 0-2.23l.215-.367.044-.043h.044v-.022z" />
            <path
                fill="#FFF"
                d="M668.732 102.474c.715-.477.866-.866 1.73-1.256-.735.975-1.882 1.43-1.514 2.748-.585.044-.433-.302-1.45 1.214-1.126 1.688-2.642 4.285-1.45 6.235.217-.433.26-1.517 1.19-3.29.54-1.02 1.73-2.273 1.905-3.25l.866.78c-.954.13-.932-.042-1.907 1.517-.778 1.234-1.73 3.334-1.796 4.87 0 1.214.5 2.08 1.517 1.733.173-1.95-.22-1.343.908-3.854.976-2.23 1.516-1.904 1.992-3.614.347.433.52.345.866.586-2.166.734-4.547 6.408-3.03 7.447.584.26 1.297-.11 1.73-.824.304-.606-.107-.715.497-2.057.586-1.365 1.15-1.732 1.8-2.707.517-.737.13-.152.216-.997.39.39.476.39.865.52-1.125.735-1.73 1.43-2.49 2.857-.433.802-1.232 3.117.477 2.945 1.69-.175 1.604-2.555 3.03-4.546.564-.78.867-.867.694-1.408 0 0 0-.064-.044-.086 2.49-.648 5.195-3.55 4.374-5.953-.434-1.256-1.213-1.517-1.948-2.015 1.017.086 1.3.65 2.122 1.406.324-.173 1.19-.866 1.298-1.298.107-.434-.043-.52-.735-1.3-.368-.433-.802-.8-1.148-1.3 1.168.478 1.947 2.34 2.597 2.383.064-1.038-1.73-2.597-2.382-3.116-.497-.39-.91-.692-1.386-.996-.563-.347-1.02-.757-1.582-.217.304.39.498-.433.91-.107l-.368-.043c-.063-.044-1.234 1.604-1.43 1.775.434.8.693.606 1.235 1.73-.323-.26-.563-.758-1.04-1.082-.433-.303-.82-.433-1.298-.498-3.788-.585-4.113 2.555-8.4.78-.65-.26-1.948-.866-2.598-.498-.955.54-.736 3.94 2.597 4.07 1.17.064 1.58 0 2.598.692l-.023.024z"
            />
            <path d="M716.06 35.77c.152 2.684.65 7.36 1.733 8.962 1.775 2.468-.043 3.616-.152 3.68l-2.12 1.517h-.044l-5.196 2.38h-.043s-4.654 1.17-5.954-1.19c-.28-.54-.54-.953-.758-1.255.218.13.478.216.715.28l.434.76c1.08 1.99 5.15 1.08 5.476.996l5.153-2.316 2.122-1.515s1.58-.91.064-3.074c-1.15-1.58-1.647-6.062-1.843-8.79.152-.11.283-.218.434-.39l-.02-.047zM702.335 48.93h-.217l.087-.13.13.13z" />
            <path d="M695.21 27.195c0 .65.045 7.015 1.906 8.444l.13.063v.13l-.15.433c-.305 1.04-.39 1.3.044 2.814.173.694.605 1.364 1.21 2.34.824 1.3 1.95 3.074 3.292 6.06.433.868.997 1.452 1.515 1.863.693.498 1.387.714 2.102.692.712 0 1.448-.217 2.163-.65 1.147-.65 2.253-1.69 3.184-2.944l1.082-1.384c1.405-1.732 2.466-3.032 1.947-5.197.15 0 .304-.044.434-.087.584 2.338-.562 3.745-2.035 5.542-.347.433-.714.866-1.082 1.385-.975 1.3-2.1 2.382-3.292 3.03-.777.477-1.578.738-2.38.738-.823 0-1.624-.217-2.382-.78-.584-.433-1.147-1.083-1.646-1.992-1.3-2.988-2.447-4.763-3.247-6.062-.65-.997-1.083-1.69-1.257-2.382-.432-1.732-.39-1.948-.043-3.096 0-.11.043-.217.086-.326-1.795-1.58-1.947-7.23-1.947-8.444-.584-.087-.518-.304.087-.563.15-.064.39-.15.693-.217 1.233-.433 3.465-.866 4.287 0 .65.65 1.84-.13 3.073-.952.736-.5 1.518-.997 2.253-1.256.368-.15.8-.15 1.298-.086 1.018.217 2.166.822 3.16 1.515.954.65 1.69 1.386 1.82 1.69v.216c0 .216.216 1.646.367 2.73l.11.864c.474-.324 1.297-.757 2.164-.974.54-.13 1.08-.216 1.56-.107.497.086.93.303 1.212.757.303.433.432 1.148.345 2.122-.28 2.685-1.514 3.377-2.553 3.724-.13-.13-.304-.218-.434-.305.976-.304 2.274-.78 2.556-3.465.106-.865 0-1.472-.262-1.84-.216-.324-.563-.498-.952-.563-.39-.064-.866 0-1.343.087-1.082.28-2.12.866-2.294 1.125 0 .063-.045.107-.09.13-.173.216-.324.216-.433.13-.107-.087-.13-.26-.085-.39.043-.13.13-.26.26-.304h.13c-.087-.217-.26-.52-.325-.93-.174-1.083-.39-2.512-.346-2.858-.13-.26-.8-.867-1.688-1.516-.91-.65-2.035-1.235-3.03-1.407-.37-.065-.737-.065-1.04.043-.693.26-1.43.757-2.164 1.234-1.364.91-2.685 1.775-3.595.866-.693-.65-2.707-.26-3.854.11l-.5.172-.02.025z" />
            <path d="M696.79 37.09v-.542c-.042-2.057-1.362-2.988 2.253-2.165.997.26 1.364.39 1.604 1.43l.044.346c.39 3.723-.28 2.813-.54 4.2-.22.822.475 1.363 1.038 1.688.952.563 2.597.26 2.9.064-.713 0-1.19-.043-1.948-.064-.217 0-.606-.107-.78-.26-.475-.433-.952-.823-.605-1.516.52-1.04.324-2.51.26-3.79-.086-1.514-.54-2.12-1.86-2.77-1.993-.996-1.906-.822-3.25-.865-.344-2.166-.474-3.594-.474-4.547l-.39.087s.283 4.503.76 5.63c.475 1.082.8 1.406 1.018 3.03l-.027.043zm12.17 4.223c-2.1.78-4.157 1.667-6.452 1.905-.692.064-1.43-.15-2.1 0 .302.324.648.433 1.017.78 1.17 1.19 1.624 3.896 4.027 3.247 2.424-.65 2.598-3.594 3.81-5.716.217-.13.217-.11.5.26-.22-.715-.22-.52-.78-.932l-.043.476.02-.02z" />
            <path d="M708.96 41.333l-.867.346c-1.797.65-3.594 1.34-5.585 1.515-.305.043-.65 0-.954 0-.367-.043-.758-.064-1.082 0 .13.15.28.26.433.368.174.108.347.217.52.433.368.324.65.8.932 1.298.692 1.148 1.406 2.34 3.096 1.884 1.688-.432 2.272-2.035 2.9-3.68.26-.65.52-1.364.866-1.992.218-.15.26-.13.498.152-.13-.433-.217-.476-.39-.585-.108-.043-.217-.107-.345-.217v.433h-.044l.02.043zm-.867.304l.866-.324v-.477h.043c.15.13.26.217.347.217.217.15.282.217.432.737l.044.107-.063-.107-.065-.064c-.216-.282-.26-.304-.432-.217-.368.65-.65 1.34-.867 2.034-.65 1.646-1.257 3.248-2.968 3.68-1.73.477-2.423-.735-3.14-1.947-.28-.433-.563-.93-.908-1.3l-.477-.323c-.216-.11-.346-.217-.52-.39.39-.107.78-.087 1.17-.043.324 0 .648.044.953 0 1.948-.172 3.766-.865 5.563-1.515l.02-.068z" />
            <path
                fill="#FFFAF0"
                d="M701.858 43.585c.604 1.407 2.38.996 3.638.542 1.406-.52 2.38-.954 2.944-2.273-2.057.713-4.33 1.946-6.582 1.73z"
            />
            <path d="M697.073 35.856c-.65 0 .65.563 0 .26.26-.648.434-.648 1.147-.78.15 1.624 1.3 1.343 1.56.152l.65.26c-.304-.605-1.084-.866-1.776-.823-1.3.044-1.212.326-2.035.93.26.347.172.564.476 0h-.023z" />
            <path
                fill="#FFFAF0"
                d="M700.754 35.92l-.866-.563c-.26 1.19-1.516 1.603-1.667 0-.712.11-.866.087-1.146.757 1.948.93 1.992.758 3.68-.217v.023z"
            />
            <path d="M700.537 35.92l-.346-.217-.26-.173c-.107.39-.325.693-.586.865-.13.064-.26.108-.433.108-.13 0-.26-.044-.388-.15-.217-.152-.346-.434-.39-.868h-.043c-.52.087-.65.11-.867.563 1.667.78 1.84.693 3.116-.043l.217-.108-.02.023zm-.216-.432l.476.346.088.13-.108.044-.347.217c-1.407.757-1.58.866-3.465-.044l-.064-.042.045-.087c.216-.65.39-.692 1.038-.8l.152-.043h.107v.13c.064.432.217.713.368.866.064.064.173.086.26.086s.173 0 .28-.087c.218-.152.435-.476.522-.867l.042-.172.11.087.387.26.11-.022zm4.743 5.672c2.23.39 1.73-1.883.585-1.775 1.17 1.04.174 1.515-.606 1.775h.02zm2.62-6.02l-2.384.434c1.19.37 1.646-.217 2.382-.433z" />
            <path
                fill="#ED1C24"
                d="M703.784 46.465c2.166 1.428 3.248-.585 3.767-1.992 0 0-2.727.606-3.766 1.948v.045z"
            />
            <path d="M703.72 41.16c-.368 0 .477-.822 1.083-1.04.605-.214.54.434.54.434l-1.623.606z" />
            <path d="M703.72 41.203h-.107c-.043-.064-.043-.13 0-.217 0-.043.043-.107.107-.173.218-.282.692-.65 1.083-.78.65-.217.605.498.605.498l-.043.044-1.624.585-.02.043zm-.043-.108l1.624-.585c0-.13-.042-.52-.476-.346-.347.108-.78.433-.996.693l-.108.174c-.043 0-.043.044-.043.044v.02zm9.2-5.673c.087-1.19.694-5.023 2.946-4.264.498.173.692.693.433 1.168-.044-.28 0-.585-.26-.78-1.56-1.233-3.033 3.465-3.14 3.897l.022-.02z" />
            <path
                fill="#170000"
                d="M700.407 36.83c-.866.326-1.73.433-2.383.347 1.387.368 1.8-.13 2.383-.325v-.022zm.13 7.08c-.8-.52-.13-1.19.325-1.212-.282.26-.585.995-.325 1.212z"
            />
            <path
                fill="#404041"
                d="M712.813 50.924s.908-4.827.13-5.455c-.78-.65 5.672-2.924 6.646-3.747.975-.824 1.797 6.71 1.797 6.71s10.523-5.022 12.298-4.87c4.07-2.923 7.73-5.52 8.444-6.582.605-2.597 2.206-9.742 6.06-16.627 1.517 1.148 4.85 5.37 7.644 4.396-1.517 9.914-3.94 15.305-4.914 16.973-2.75 4.763-5.803 6.884-8.574 11.128-1.342 2.08-6.062 7.08-8.746 9.96.325 5.194 1.517 11.647 2.382 14.98-6.345 6.387-18.316 11.26-24.51 10.025-6.19-1.3-6.537-.758-9.31-1.473-.432-3.16-1.298-7.577-2.163-10.694L699.26 74c-2.382 5.5-5.673 12.125-5.845 12.43-1.3 1.947-10.22 18.056-11.692 16.822-1.298-1.125-.15-5.24-6.104-7.902l-.172-.152c1.58-1.905 1.73-3.355 3.527-5.28 3.57-3.834 3.097-4.482 5.26-9.03 1.084-3.636 1.43-7.143 2.644-11.213.215-5.262.086-13.51 3.202-13.532h.585c.13 0 .26-.065.368-.086l-.52-1.147 10.912-2.21-1.818-4.935c-.044-.13 1.146-.303 2.034-.433 3.096 5.132 5.066 1.775 5.152 3.118.217 3.14 3.097 1.43 3.073 1.732-.13 1.407 2.922-1.256 2.922-1.256h.02v-.002z"
            />
            <path
                fill="#E06938"
                d="M707.4 52.137l-.65-2.166c-.562.174-1.234.305-1.796.218.304 1.234.648 2.51.714 2.295.866-.302 1.732-.346 1.732-.346zm-5.845-5.024l-1.948.563s.28 1.862.605 1.732l2.274-.757c-.26-.303-.52-.65-.76-1.082l-.172-.433v-.022z"
            />
            <path d="M706.967 51.877l-.433-1.517-.693.152h-.474c.13.498.217.954.346 1.3 0 0-.064.216-.15.367.52-.218 1.08-.282 1.405-.326v.023zm.11-2.013l.647 2.165.088.432h-.434s-.736 0-1.516.28c-.26.585-.52.174-.8-.757l-.433-1.73-.13-.434.473.045c.22.043.478.043.737 0 .304-.044.65-.108.91-.217l.325-.108.106.325h.026zm-5.717-2.383l-1.406.434.173 1.19 1.84-.65-.106-.15-.39-.65-.086-.15-.024-.023zm-1.84-.128l1.948-.542.282-.064.108.26.087.217.086.173.37.562c.128.217.258.347.387.5l.305.432-.498.108c-.997.303-1.86.65-2.21.758h-.043c-.69.26-1.08-1.992-1.08-1.992v-.282l.26-.086v-.044z" />
            <ellipse fill="#E06938" cx="712.531" cy="57.506" rx="1.559" ry="1.602" />
            <path d="M712.53 55.558c.52 0 .997.217 1.344.584.303.346.52.824.52 1.364s-.218 1.018-.543 1.364c-.366.346-.864.563-1.362.563-.52 0-.997-.217-1.343-.585-.324-.346-.543-.867-.543-1.365 0-.497.22-1.017.543-1.363.346-.368.822-.585 1.343-.585l.043.023zm.867 1.04c-.216-.218-.52-.39-.866-.39s-.648.15-.865.39c-.217.216-.368.54-.368.908 0 .345.13.65.368.91.217.217.52.368.866.368.347 0 .65-.15.867-.39.217-.216.368-.563.368-.91 0-.367-.13-.692-.368-.91v.023z" />
            <ellipse fill="#E06938" cx="714.394" cy="64.802" rx="1.559" ry="1.603" />
            <path d="M714.394 62.875c.52 0 .996.218 1.34.563.348.345.543.866.543 1.364 0 .496-.216 1.017-.542 1.363-.345.367-.822.585-1.34.585-.52 0-.998-.218-1.3-.585-.37-.347-.586-.823-.586-1.363s.216-1.02.562-1.364c.324-.346.8-.563 1.3-.563h.022zm.866 1.02c-.218-.218-.52-.37-.866-.37s-.65.13-.867.37c-.216.215-.368.56-.368.908 0 .368.15.692.367.91.218.216.52.367.867.367.346 0 .648-.15.866-.39.216-.217.368-.562.368-.93 0-.347-.13-.65-.368-.91v.044z" />
            <ellipse fill="#E06938" cx="716.212" cy="71.794" rx="1.559" ry="1.602" />
            <path d="M716.212 69.848c.52 0 .975.216 1.3.585.367.345.583.822.583 1.363 0 .542-.216 1.018-.562 1.364-.347.345-.8.563-1.3.563-.562 0-1.017-.218-1.363-.585-.345-.346-.563-.866-.563-1.363 0-.498.218-1.02.542-1.364.345-.367.8-.583 1.34-.583l.022.02zm.866 1.038c-.217-.218-.54-.39-.866-.39-.324 0-.65.15-.866.39-.216.216-.39.542-.39.91 0 .367.13.648.347.91.217.216.54.366.866.366s.648-.15.864-.39c.22-.216.39-.562.39-.908s-.15-.692-.367-.91l.02.022zm-41.98 30.353c.11.604-.866 1.19-.952 2.943 0 .65.13.758-.064 1.212-.866-2.382.086-2.727 1.04-4.156h-.024zm66.423-63.22c.283-.432 1.345-1.514 1.95-1.167-.498.476-1.3.91-1.95 1.148v.02zm-57.718 43.82c1.73-1.797.8-1.082 2.75-2.272-1.56 1.668-.65.866-2.772 2.252l.022.02z" />
            <path
                fill="#202020"
                d="M681.354 101.28s5.5-8.875 11.605-20.046c2.662-4.87 2.25-14.115 2.25-16.088 1.3 2.73 3.682 9.008 3.81 9.18-2.38 5.413-5.52 11.692-5.692 11.994-1.213 1.818-8.66 15.285-11.367 16.67-.28-.173-.497-.996-.605-1.688v-.02z"
            />
            <path d="M712.488 50.75c.13-.713.823-4.545.217-5.022-.217-.217-.217-.476 0-.757.433-.54 2.273-1.362 3.94-2.1 1.255-.562 2.38-1.08 2.748-1.363 1.235-1.04 2.035 4.763 2.253 6.43 2.38-1.125 14.29-6.58 12.47-3.767-.15.217-.28.93-.367 1.95-.218-.065-.434-.065-.607 0 .087-1.193.217-2.015.433-2.297.757-1.212-12.038 4.872-12.08 4.872l-.433.216v-.432c0-.043-.78-6.93-1.3-6.495-.39.368-1.58.866-2.858 1.472-1.56.693-3.247 1.45-3.68 1.885.757 1.04-.13 5.63-.13 5.672v.087l-.108.063c-.043 0-3.205 2.814-3.465 1.364-.26.064-.584.15-.953.172-.93.064-2.015-.26-2.166-2.23 0-.044.108-.044.064-.044-.086 0-.216 0-.304.065-.908.217-2.597.65-4.763-2.814h-.107l-1.298.26 1.688 4.655.132.347-.37.064-10.5 2.123.434.866c-.132 0-.37.063-.65.15l-.52-1.126-.175-.367.434-.087 10.52-2.1-1.688-4.59c-.087-.216.043-.39.304-.497.304-.11 1.018-.217 1.667-.304l.324-.065h.218l.107.15c1.95 3.25 3.377 2.903 4.113 2.708l.433-.087c.39 0 .65.107.65.65.107 1.428.823 1.666 1.45 1.623.324 0 .605-.108.867-.15l.43-.066c.22 0 .348.15.326.39-.062.542 1.84-1.082 2.273-1.45l.025-.023zM694.8 63.46c-.217.087-.432.13-.65.15l5.564 12.126.65 2.488c.735 2.49 1.516 5.695 1.73 8.162l.13 1.3c14.615 2.316 20.57 1.45 33.517-8.444l.823-.607-.585-.865c-.8-3.572-1.86-10.738-2.165-17.104-.108-1.884-.174-3.746-.217-5.52-.217.345-.433.606-.65.866l.218 4.72c.304 6.278 1.082 13.466 2.122 17.037-3.16 2.944-6.863 5.326-10.934 6.928-3.962 1.604-8.335 2.47-12.883 2.47-1.517 0-3.03-.11-4.504-.305-1.43-.217-2.814-.433-4.2-.802-.28-2.466-1.08-5.584-1.73-8.008l-.694-2.513v-.065L694.8 63.46z" />
            <path d="M703.07 47.546c.65.563 1.406.91 1.885.433.324-.305.542-.802.714-.823.496 0 1.515 1.624 2.662-.606 1.3-2.51 1.234-3.895 1.148-4.61-2.016-2.382-2.47 1.472-5.327.173.11 1.862-5.63-.217-3.03 2.49.908.997 1.405 2.08 1.947 2.966v-.024zm-7.664-19.225c-2.165 2.665-5.26.066-4.677 0 .063 0 2.468 1.257 2.424-1.665-.043-2.902 5.413-8.597 9.657-6.712 2.772 1.3 9.244.107 11.69 3.356 2.384 3.246 1.58 7.47 1.58 7.47s-2.164-.998-3.397 2.445c-.26.542-.434 1.69-.045 2.34.78 1.406 2.035 4.85 1.518 6.43-.218.713-.5 1.298-1.127 2.164-.606.8-1.603 1.948-2.035 2.64-.866 1.56-1.19 2.057-2.902 2.946-4.243 2.166-6.147-.433-7.795-4.006-3.614-4.59 2.317-3.14 3.897-3.616 2.512-.65 4.07-3.032 5.846-1.603.088 0 .174 0 .218-.042.303.13.584.368.866.693.54-.324 1.56-1.45.8-4.61-.107-.434-.432-1.235-.605-2.167l-.088-.15c-.324-1.842-.586-4.028-.563-4.72 0-.283-.434-1.625-.564-1.82-.106-.086-.172-.303-.345-.345-3.247-.867-5.26.325-7.427.91-1.73.475-2.165.216-3.57-.044-1.236-.216-2.6-.65-3.334.174l-.024-.067zm14.398 19.12c0 .085-.043.15-.087.215l-.217.152.324-.368h-.02z" />
            <path
                fill="#E06938"
                d="M701.858 53.392s-.434-2.165-1.56-1.515c-1.082.65-12.125 1.796-12.125 1.796l.8 2.468 12.86-2.77.025.022z"
            />
            <path d="M701.468 53.132c-.15-.476-.498-1.255-1.017-.953-1.04.604-9.872 1.558-11.864 1.773l.606 1.797 12.254-2.642.022.024zm-1.34-1.515c1.514-.867 2.055 1.667 2.055 1.69l.043.323-.324.087-12.86 2.77-.304.067-.107-.28-.8-2.47-.13-.39.432-.042s10.912-1.125 11.95-1.732l.045-.023z" />
            <path
                fill="#202020"
                d="M733.88 62.01c2.684-2.86 7.144-7.36 8.507-9.462 2.77-4.222 5.63-6.28 8.402-11.042.864-1.45 3.18-8.227 4.912-16.454h-1.73s-3.897 13.12-4.7 15.07c-.8 1.947-15.674 15.22-15.674 15.22l.28 6.667z"
            />
            <path
                fill="#E06938"
                d="M746.544 24.533c.218-.433.52-1.235.737-1.624.996 1.86 4.914 4.913 7.73 4.762-.107.757-.15.997-.433 1.95-6.71-.608-8.314-5.09-8.055-5.09h.022z"
            />
            <path d="M746.023 24.165l.218-.54.435-1.02.584-1.082.605 1.083c.498.93 1.84 2.208 3.398 3.183 1.256.756 2.598 1.297 3.745 1.233l.78-.043-.107.78-.173.953-.304 1.125-.15.498-.5-.043c-4.763-.433-7.143-2.77-8.075-4.265-.217-.324-.346-.648-.432-.866-.152-.325-.174-.65-.11-.8 0-.108.045-.174.088-.218v.02zm1.257-1.255c-.217.39-.52 1.19-.736 1.624-.26 0 1.364 4.48 8.01 5.108.325-.974.37-1.212.435-1.948-2.815.13-6.714-2.923-7.71-4.764v-.02z" />
            <path d="M755.465 25.14c-2.253.345-4.634-1.95-6.28-3.508l-.865-.824c-3.507 6.366-5.088 12.883-5.78 15.74l-.108.476v.065l-.044.042c-.692 1.038-4.048 3.464-7.88 6.192l-.562.433c.043-.282-.304-.304-.91-.173l1.083-.78c3.722-2.664 6.992-5.023 7.685-6.02l.086-.432c.716-2.923 2.34-9.677 5.998-16.238l.218-.324.28.217c.348.26.758.65 1.236 1.125 1.666 1.58 4.113 3.897 6.104 3.248l.52-.217-.086.542c-.606 3.962-1.344 7.188-2.1 9.743-1.127 3.94-2.274 6.322-2.86 7.362-1.515 2.64-3.138 4.48-4.762 6.28-1.3 1.514-2.6 2.986-3.81 4.87-1.364 2.078-5.977 6.992-8.66 9.894-.13.086-.217.217-.347.28v-.865c2.686-2.88 7.145-7.666 8.488-9.678 1.256-1.95 2.554-3.42 3.897-4.916 1.56-1.84 3.16-3.637 4.653-6.235.587-.995 1.69-3.333 2.816-7.186.693-2.447 1.406-5.5 1.99-9.202v.087z" />
            <path
                fill="#E06938"
                d="M721.3 49.604s-.606-2.382.735-2.382c1.344.087 12.082-4.72 12.082-4.72l.476 2.945-13.293 4.157z"
            />
            <path d="M720.997 49.668s-.716-2.814 1.038-2.75c1.3.088 11.908-4.675 11.95-4.697l.39-.173.065.433.434 2.923.063.28-.26.087-13.272 4.158-.324.086-.083-.346zm1.018-2.1c-.65 0-.563 1.083-.434 1.646l12.645-3.984-.41-2.23c-1.948.823-10.61 4.633-11.844 4.546l.045.022zM675.92 95.112c4.114 1.948 4.852 4.545 5.327 6.322.217.692.368 1.3.692 1.517.13.13.585-.325 1.3-1.082 2.25-2.728 6.06-9.2 8.27-12.99.757-1.3 1.34-2.23 1.603-2.64.217-.306 3.572-7.147 5.976-12.667l.367.8c-2.383 5.46-5.63 11.91-5.803 12.213-.26.39-.822 1.363-1.58 2.642-2.23 3.787-6.062 10.306-8.357 13.032-.952 1.082-1.472 1.646-1.948 1.257-.478-.433-.91-1.083-1.127-1.95-.476-1.688-1.147-4.155-5.15-5.953h-.088l-.174-.15-.216-.217.173-.26c.715-.824 1.126-1.603 1.518-2.383.54-.91 1.037-1.84 2.033-2.9 2.6-2.814 3.032-3.832 3.94-6.02.324-.78.692-1.69 1.256-2.9.52-1.73.867-3.465 1.234-5.24.39-1.883.78-3.83 1.386-5.91 0-.585.043-1.146.064-1.73.173-5.306.368-12.06 3.464-12.06h.867l.085.217c-.433.107-1.19.346-1.58.584-1.84 1.02-2.035 6.713-2.165 11.26 0 .65-.043 1.233-.065 1.774v.086c-.648 2.08-1.017 4.006-1.405 5.89-.368 1.796-.715 3.55-1.235 5.325v.044c-.585 1.19-.975 2.098-1.3 2.878-.93 2.23-1.363 3.334-4.05 6.213-.95.997-1.427 1.885-1.903 2.772-.39.714-.78 1.43-1.385 2.21l-.027.02z" />
            <path
                fill="#E06938"
                d="M684.02 100.7c-.218-2.534-3.248-6.974-6.452-8.12.475-.802.604-1.083 1.298-2.057 6.388 3.463 7.145 7.08 6.972 7.707-.368.648-.91 1.516-1.818 2.49v-.02z"
            />
            <path d="M683.368 100.764c-.106-1.235-.974-2.967-2.164-4.547-1.082-1.343-2.47-2.533-3.854-3.03l-.736-.282.433-.65.218-.432c.305-.52.5-.866 1.082-1.668l.346-.432.5.216c2.466 1.362 4.112 2.728 5.194 3.94 1.948 2.165 2.208 4.026 2.078 4.5v.09l-.064.085-.735 1.148c-.324.434-.692.866-1.17 1.406l-.996 1.082-.13-1.45-.002.022zm.65-.065c.91-.998 1.45-1.842 1.82-2.47.15-.605-.585-4.2-6.93-7.685-.736.997-.866 1.254-1.34 2.056 3.203 1.127 6.234 5.566 6.45 8.1z" />
            <path
                fill="#E06938"
                d="M714.025 87.232l-9.872-34.49s8.596-1.515 8.747-2.38c.13-.823-.174-5.326-.174-5.326l7.102-3.357c.217-.087.52.865.758 2.056-.867.78-5.89 1.603-5.78 2.467.107.867.217 3.464.217 5.196.064 1.602-8.335 2.38-8.075 3.312l8.812 32.302c-.563.087-1.147.13-1.73.174v.044h-.005z"
            />
            <path d="M713.68 87.297l-9.83-34.423-.087-.368.346-.087s8.355-1.516 8.442-2.1c.152-.867-.15-5.283-.15-5.305v-.217l.217-.087 7.1-3.332h.088c.217-.064.347.042.475.217.088.13.153.302.218.498.152.367.306.93.435 1.56v.172l-.11.11c-.43.43-1.644.777-2.857 1.146-1.405.434-2.814.867-2.814 1.083l.13 2.08c.066 1.016.087 2.228.11 3.18 0 1.17-2.75 1.84-5.132 2.382l-1.58.432c-.866.217-1.43.39-1.43.433l8.812 32.28.108.37-.368.043-.865.063c-.327 0-.652.064-.912.087h-.26l-.065-.217h-.024zm-9.094-34.272l9.742 34.142c.217 0 .434 0 .65-.044l.476-.064-8.79-32.216c-.15-.52.65-.867 1.86-1.213.478-.106 1.04-.216 1.605-.388 2.163-.52 4.632-1.125 4.61-1.732 0-.953-.065-2.166-.108-3.183l-.15-2.035c-.088-.735 1.602-1.255 3.246-1.73 1.04-.327 2.056-.65 2.47-.91-.11-.5-.218-.954-.35-1.3l-.105-.217-6.668 3.14c.043.93.26 4.44.13 5.196-.152.954-6.993 2.295-8.616 2.6v-.044h-.004z" />
            <path
                fill="#404041"
                d="M694.952 22.65c-.217-.867-2.49-7.037-2.49-7.037s9.2-8.66 24.118-.433l-2.252 8.162c-.65-.693-15.74-5.283-19.376-.693z"
            />
            <path d="M694.627 22.714c-.217-.866-2.47-6.972-2.47-6.992l-.062-.216.15-.13s9.353-8.79 24.465-.52l.218.15-.043.26-2.253 8.142-.15.563-.39-.432c-.477-.346-4.115-1.516-8.185-2.166-4.048-.648-8.443-.757-10.436 1.125l-.304.325-.43.5-.133-.65.024.042zm-1.776-6.993c.35.93 1.733 4.764 2.254 6.28 2.166-2.015 6.712-1.95 10.912-1.3 3.615.607 6.93 1.647 8.098 2.166l2.078-7.534c-13.468-7.275-22.085-.693-23.34.39z" />
            <path d="M693.89 19.077s9.31-5.673 21.283.757l.217.13v.26c0 .65-.65 3.248-.65 3.248l-.15.52-.476-.26s-10.652-5.717-18.922-.477l-.434.303-.217-.52-.865-3.463-.064-.325.28-.173zm.217.368s.78 3.248.865 3.465c8.445-5.413 19.356.433 19.356.433s.65-2.512.65-3.14c-11.756-6.28-20.87-.758-20.87-.758zm9.136 13.813c.91.173 2.533-.91 3.528-1.19 1.517-.434 2.274-.044 3.596 0-3.074-2.49-7.752-.65-7.102 1.168l-.02.022zm.91 1.818c.975-.217 1.516-.563 2.598-.605.975 0 1.732.37 2.23-.345-1.517-.93-4.2.65-4.85.952h.022z" />
            <path
                fill="#9A9896"
                d="M710 27.845c-.717-1.862-.11-2.533.864-2.814-.54.37-1.407.93-.865 2.815zm.864-1.558c1.73 1.082 1.774 2.945 1.688 4.763v.108c-.15-.108-.367-3.117-1.73-4.87h.042zm1.127-.563c1.342-.368 2.534 1.86 2.382 3.247-.498-1.364-1.018-2.662-2.38-3.246zm.476-1.386c2.47.433 2.968 1.516 2.814 3.983-.498-2.38-.648-2.92-2.814-3.982zm-2.9-.606c-4.548-1.797-12.125-1.73-14.572 3.4l-.432 1.146c.324-1.45.432-2.014 1.516-3.182 3.312-3.573 9.526-3.53 13.487-1.364z"
            />
            <path
                fill="#9A9896"
                d="M708.137 25.03c-4.244-.346-3.334 2.6-9.744 1.473-.107 0-.044 0-.173-.044.585-.218 1.99.063 2.015.063 3.528-.086 5.13-2.51 7.88-1.515l.022.023z"
            />
            <path d="M697.44 35.207c.866-.584 1.993-.953 2.9-.325-.95.064-1.946.216-2.9.325zm6.712-.716c.478-2.25 3.68-1.688 3.833-1.514h-.108c-.432.107-.432.15-.866.13-1.514-.087-2.51 1.125-2.813 1.45-.088.11-.11.11-.045-.065zm10.242-2.596c-.065.78-.218 1.472-.218 2.34-.65-.284-.346-2.232.218-2.34zm-10.09 1.234l-.02-.02m5.02 1.233c.564.174.564.26.932.65h-.28c-.543-.108-.39-.152-.65-.65zm-.02-.13c.54.174.758.044 1.3-.086l-.282-.13c-.498-.216-.54-.044-1.02.216z" />
            <path
                fill="#E06938"
                d="M694.107 19.445s9.094-5.543 20.85.758c0 .65-.65 3.14-.65 3.14s-10.89-5.846-19.377-.433l-.865-3.465h.042z"
            />
            <path
                opacity=".3"
                fill="#FFF"
                d="M708.785 21.35c.65-2.814 1.234-5.586 1.862-8.38 1.17.348 2.382.803 3.615 1.365l-1.798 8.184c-1.19-.477-2.38-.91-3.68-1.192v.022z"
            />
            <path d="M744.14 83.486c-.172.218-.345.368-.518.542-8.335 8.335-19.832 13.488-32.54 13.488-5.695 0-11.15-1.038-16.174-2.923l5.022-9.7c3.464 1.17 7.254 1.798 11.15 1.798 10.674 0 20.243-4.762 26.694-12.253l6.367 9.05z" />
            <g>
                <path
                    fill="#E06938"
                    d="M253.68 61.107c-2.506-2.363-5.37-3.15-8.733-3.15-4.15 0-6.44 1.29-6.44 3.507 0 2.29 2.075 3.578 6.584 3.865 6.657.43 15.103 1.93 15.103 11.308 0 6.227-5.082 11.596-15.174 11.596-5.584 0-11.167-.932-16.32-6.3l4.295-6.226c2.505 2.79 8.23 4.865 12.167 4.938 3.292.072 6.37-1.646 6.37-4.223 0-2.434-2.004-3.436-7.014-3.72-6.656-.503-14.6-2.937-14.6-10.952 0-8.16 8.445-11.022 14.886-11.022 5.51 0 9.663 1.074 13.742 4.652l-4.867 5.727zM271.786 72.486c.572 4.367 4.366 7.516 10.52 7.516 3.222 0 7.445-1.217 9.45-3.29l5.582 5.51c-3.722 3.864-9.805 5.726-15.173 5.726-12.167 0-19.396-7.516-19.396-18.824 0-10.736 7.3-18.465 18.75-18.465 11.81 0 19.182 7.3 17.823 21.827h-27.557zm19.18-7.228c-.572-4.58-4.15-6.87-9.16-6.87-4.725 0-8.59 2.29-9.878 6.87h19.04zM326.395 51.73h10.45v.287l-11.596 17.105 12.31 17.68v.215h-10.45l-7.658-11.81-7.658 11.81h-10.45v-.215l12.31-17.68-11.594-17.105v-.286h10.45l6.943 11.953 6.942-11.952z"
                />
                <path
                    fill="#231F20"
                    d="M370.84 82.508c-4.08 4.008-8.373 5.582-13.6 5.582-10.234 0-18.752-6.154-18.752-18.68s8.518-18.68 18.752-18.68c5.012 0 8.876 1.43 12.74 5.224l-5.51 5.798c-2.076-1.86-4.653-2.79-7.087-2.79-5.87 0-10.163 4.292-10.163 10.448 0 6.73 4.58 10.307 10.02 10.307 2.793 0 5.582-.787 7.73-2.936l5.87 5.728zM401.257 51.73h8.373v35.287h-8.23l-.43-5.154c-2.003 4.152-7.516 6.156-11.45 6.227-10.45.072-18.18-6.37-18.18-18.752 0-12.167 8.088-18.537 18.394-18.465 4.724 0 9.233 2.218 11.236 5.726l.287-4.87zM380.07 69.34c0 6.73 4.652 10.736 10.45 10.736 13.742 0 13.742-21.4 0-21.4-5.797 0-10.45 3.937-10.45 10.664zM439.044 87.018V68.12c0-4.65-2.434-8.874-7.228-8.874-4.724 0-7.517 4.223-7.517 8.875v18.897h-8.73V51.66h8.087l.643 4.294c1.862-3.58 5.94-4.867 9.305-4.867 4.223 0 8.446 1.718 10.45 6.585 3.148-5.01 7.23-6.442 11.81-6.442 10.02 0 14.96 6.155 14.96 16.75v19.038h-8.733v-19.04c0-4.65-1.932-8.59-6.656-8.59s-7.657 4.08-7.657 8.733v18.897h-8.736zM501.312 61.107c-2.506-2.363-5.37-3.15-8.733-3.15-4.15 0-6.44 1.29-6.44 3.507 0 2.29 2.074 3.578 6.583 3.865 6.656.43 15.103 1.93 15.103 11.308 0 6.227-5.083 11.596-15.174 11.596-5.583 0-11.165-.932-16.32-6.3l4.297-6.226c2.504 2.79 8.23 4.865 12.166 4.938 3.292.072 6.37-1.646 6.37-4.223 0-2.434-2.005-3.436-7.014-3.72-6.658-.503-14.603-2.937-14.603-10.952 0-8.16 8.445-11.022 14.887-11.022 5.512 0 9.663 1.074 13.74 4.652l-4.865 5.727zM522.064 82.508c0 6.8-10.307 6.8-10.307 0s10.307-6.8 10.307 0z"
                />
                <g fill="#231F20">
                    <path d="M550.33 51.114h10.45v.286l-11.596 17.106 12.31 17.678v.216h-10.45l-7.657-11.81-7.66 11.81H525.28v-.217l12.31-17.678L525.995 51.4v-.286h10.45l6.942 11.952 6.944-11.952zM586.974 51.114h10.45v.286l-11.596 17.106 12.31 17.678v.216h-10.45l-7.656-11.81-7.66 11.81h-10.448v-.217l12.31-17.678L562.64 51.4v-.286h10.448l6.943 11.952 6.944-11.952zM623.617 51.114h10.45v.286L622.47 68.506l12.312 17.678v.216h-10.448l-7.66-11.81-7.657 11.81h-10.45v-.217l12.31-17.678L599.283 51.4v-.286h10.45l6.942 11.952 6.943-11.952z" />
                </g>
                <g fill="#231F20">
                    <path d="M41.232 86.574V67.68c0-4.652-2.434-8.875-7.23-8.875-4.723 0-7.514 4.223-7.514 8.875v18.895h-8.732V51.218h8.088l.645 4.294c1.86-3.58 5.94-4.867 9.303-4.867 4.223 0 8.446 1.718 10.45 6.584 3.15-5.01 7.228-6.443 11.81-6.443 10.02 0 14.957 6.156 14.957 16.75v19.036h-8.73V67.537c0-4.652-1.934-8.59-6.657-8.59-4.724 0-7.658 4.08-7.658 8.732v18.894h-8.733zM114.52 51.29L92.98 101.318h-9.59l6.583-15.316-14.1-34.712h10.164l5.37 15.245 3.363 9.95 3.65-9.735 6.44-15.46h9.664zM124.18 86.574V59.45h-6.583v-7.66h6.584v-2.36c0-9.45 6.37-13.814 13.457-13.814 3.077 0 6.298 1 9.16 2.863l-3.22 6.513c-1.86-1.217-3.58-1.86-5.368-1.86-3.007 0-5.297 2.002-5.297 6.297v2.362h10.377v7.658h-10.377v27.125h-8.732zM180.364 51.29h8.375v35.284h-8.232l-.43-5.152c-2.004 4.152-7.515 6.154-11.452 6.227-10.45.07-18.18-6.37-18.18-18.753 0-12.167 8.09-18.538 18.395-18.466 4.725 0 9.234 2.22 11.238 5.727l.286-4.867zM159.18 68.896c0 6.73 4.65 10.736 10.45 10.736 13.74 0 13.74-21.4 0-21.4-5.8 0-10.45 3.936-10.45 10.664zM229.033 51.218l-15.03 35.5h-9.448l-15.103-35.5h9.52l4.867 11.667 5.44 14.887 5.367-14.744 4.868-11.81h9.518z" />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.423 61.973c.913 1.34 39.19-5.52 54.605-7.966 3.873-.614 6.587-.966 6.525-1.586-.504-4.992-66.992.952-61.13 9.553z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#EC1C24"
                    d="M69.42 50.168c-.422-6.066.37-25.217-.054-31.283-20.674-9.63-54.69-1.092-65.973 12.08l7.58 28.827c.002 0 37.588-15.287 58.447-9.624z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M68.283 48.784c-.114-3.357-.035-8.813.045-14.268.085-5.853.17-11.707.01-14.915-9.566-4.245-21.95-4.63-33.53-2.51-12.69 2.323-24.356 7.637-30.236 14.158L11.7 58.352c6.246-2.414 37.356-13.828 56.583-9.568zm2.176-14.242c-.093 6.268-.184 12.537.025 15.555l.105 1.507-1.446-.393c-20.52-5.57-57.67 9.538-57.777 9.58l-1.115.45-.31-1.174-7.58-28.827-.144-.55.37-.43c6.06-7.075 18.43-12.833 31.845-15.29 12.203-2.234 25.318-1.753 35.38 2.935l.575.267.044.64c.212 3.048.12 9.39.027 15.73z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#FBB218"
                    d="M68.333 38.732s-27.693-8.725-59.665 8.96c.35 1.88 2.004 10.51 2.004 10.51s17.664-11.185 57.863-8.035c.035-.84-.202-11.435-.202-11.435z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M68.333 38.732s-27.693-8.725-59.665 8.96c.35 1.88 2.004 10.51 2.004 10.51s17.664-11.185 57.863-8.035c.035-.84-.202-11.435-.202-11.435zm-60.698 7.08c32.763-18.123 61.237-9.153 61.32-9.128l1.48.45.04 1.548c0 .03.237 10.697.2 11.57l-.093 2.232-2.215-.174c-39.492-3.095-56.52 7.694-56.57 7.724l-2.652 1.63-.582-3.065c-.004-.024-1.698-8.88-2.004-10.52l-.283-1.516 1.358-.752z"
                />
                <path
                    opacity=".29"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#F4CDAA"
                    d="M50.06 49.676c-.242-11.122-.424-22.69-.667-33.813 3.04.158 6.154.257 9.487.865.265 10.868.556 22.013.82 32.88-3.115.013-6.397-.09-9.64.068z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.433 61.973c.913 1.34 39.19-5.52 54.605-7.966 3.873-.615 6.587-.966 6.525-1.587-.504-4.992-66.993.952-61.13 9.553z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#3E3E3E"
                    d="M69.43 50.168c-.423-6.067.368-25.217-.055-31.284-20.674-9.63-54.69-1.092-65.972 12.08l7.58 28.827S48.57 44.506 69.43 50.17z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M68.292 48.783c-.085-2.49-.063-6.13-.014-10.07-1.423-.43-28.37-8.196-59.4 8.87l2.664 10.132c2.51-1.336 10.572-5.086 25-6.98 10.62-2.438 22.546-3.992 31.75-1.952zm.017-12.287c-4.386-1.186-30.358-7.137-59.994 8.95l-3.734-14.2c5.88-6.52 17.546-11.833 30.236-14.157 11.58-2.12 23.964-1.735 33.53 2.51.16 3.208.076 9.063-.01 14.916l-.03 1.98zm2.173 2.185c0 .032.24 10.698.202 11.572l-.093 2.23-2.216-.173c-12.743-1-23.145-.552-31.394.513-13.868 3.195-25.545 7.942-25.605 7.968l-1.115.45-.058-.22-1.05.644-.58-3.066C6.65 48.305 5.09 41.586 2.37 31.238l-.143-.548.37-.43c6.06-7.075 18.43-12.834 31.844-15.29 12.204-2.234 25.32-1.754 35.382 2.935l.573.267.045.64c.212 3.048.12 9.39.027 15.73-.02 1.432-.02 2.72.016 4.14z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#E06938"
                    d="M68.342 38.73S40.65 30.007 8.677 47.69c.35 1.883 2.004 10.513 2.004 10.513s17.665-11.187 57.864-8.036c.035-.84-.202-11.436-.202-11.436z"
                />
                <path
                    opacity=".29"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#FFF"
                    d="M50.07 49.676c-.094-4.314-.18-8.695-.262-13.09 3.66-.042 6.893.168 9.59.47.106 4.205.21 8.398.313 12.552-3.116.012-6.397-.09-9.64.068z"
                />
                <path
                    opacity=".29"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#FFF"
                    d="M49.808 36.586zm-.04-2.138c-.12-6.23-.236-12.475-.366-18.585 2.258.117 4.56.202 6.96.487h.006c.85.115 1.69.248 2.52.4v-.023c.148 6.004.302 12.093.456 18.173-2.72-.293-5.945-.493-9.577-.452zm9.63 2.61l-.127-.014.128.013z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#34202E"
                    d="M49.808 36.585l-.04-2.137c3.63-.04 6.854.16 9.576.453l.054 2.157-.146-.015c-.17-.02-.34-.038-.513-.055l-.182-.02-.463-.044-.135-.013-.27-.024-.548-.048-.21-.016c-.206-.016-.414-.032-.624-.046l-.226-.016c-.19-.013-.38-.025-.572-.036l-.345-.02-.553-.03-.352-.015-.365-.017-.155-.006h-.003l-.44-.015-.345-.01-.16-.004-.376-.01-.572-.01-.395-.003c-.188 0-.376-.003-.566-.003h-.41l-.593.004h-.072zm6.554-20.234c.83.1 1.67.222 2.527.378v.023c-.833-.152-1.677-.286-2.528-.4z"
                />
            </g>
        </svg>
    );
}
