export default function svgLangIt(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
            <g fillRule="evenodd" strokeWidth="1pt">
                <path fill="#fff" d="M0 0h512.005v512H0z" />
                <path fill="#009246" d="M0 0h170.667v512H0z" />
                <path fill="#ce2b37" d="M341.333 0H512v512H341.333z" />
            </g>
        </svg>
    );
}
