import UpArrow from '@components/partials/svg/upArrow';
import { windowWidth } from 'lib/helper/windowWidth';
import { useEffect, useState } from 'react';

import styles from '/styles/scss/navigation/backToTop.module.scss';

export default function BackToTop(): JSX.Element {
    const [isShown, setIsShown] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const width = windowWidth();

    useEffect(() => {
        window.addEventListener('scroll', () => setScrollPos(document.documentElement.scrollTop));
    }, []);

    useEffect(() => {
        if (scrollPos > 70 && width < 800) {
            setIsShown(true);
        } else {
            setIsShown(false);
        }
    }, [width, scrollPos]);

    return (
        <div
            className={styles.backToTop + ' pointer ' + (isShown ? styles.fadeIn : '')}
            onClick={() => document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })}
            onKeyDown={() => document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })}
            role="button"
            aria-hidden="true">
            <UpArrow />
        </div>
    );
}
