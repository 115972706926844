// The order of this list affects other components such as 404 page and language dropdown menu
export function getLanguageList(): any {
    const languageList = [
        {
            slug: 'sv',
            name: 'Svenska',
            title: 'OOPS!!',
            content: 'Fel: Webbsida hittades inte',
            hreflang: 'sv'
        },
        {
            slug: 'fr',
            name: 'Français',
            title: 'OOPS!!',
            content: 'Erreur: Page web introuvable',
            hreflang: 'fr'
        },
        {
            slug: 'ja',
            name: '日本語',
            title: 'OOPS!!',
            content: 'エラー：ページが見つかりません',
            hreflang: 'ja'
        },
        {
            slug: 'tr',
            name: 'Türk',
            title: 'OOPS!!',
            content: 'Hata: Sayfa bulunamadı',
            hreflang: 'tr'
        },
        {
            slug: 'pl',
            name: 'Polski',
            title: 'OOPS!!',
            content: 'Błąd: nie znaleziono strony',
            hreflang: 'pl'
        },
        {
            slug: 'hu',
            name: 'Magyar',
            title: 'OOPS!!',
            content: 'Hiba: Az oldal nem található',
            hreflang: 'hu'
        },
        {
            slug: 'nl',
            name: 'Nederlandse',
            title: 'OOPS!!',
            content: 'Fout: Webpagina niet gevonden',
            hreflang: 'nl'
        },
        {
            slug: 'ar',
            name: 'Arabic',
            title: 'وجه الفتاة!',
            content: 'خطأ: لم يتم العثور على الصفحة',
            hreflang: 'ar'
        },
        {
            slug: 'it',
            name: 'Italiano',
            title: 'OOPS!!',
            content: 'Errore: Pagina web non trovata',
            hreflang: 'it'
        },
        {
            slug: 'ro',
            name: 'Română',
            title: 'OOPS!!',
            content: 'Eroare: Nu a fost găsită',
            hreflang: 'ro'
        },
        {
            slug: 'es',
            name: 'Español',
            title: 'OOPS!!',
            content: 'Error: La página web no encontrado',
            hreflang: 'es'
        },
        {
            slug: 'ru',
            name: 'Pусский',
            title: 'OOPS!!',
            content: 'Ошибка: Страница не найдена',
            hreflang: 'ru'
        },
        {
            slug: 'pt',
            name: 'Portuguese',
            title: 'OOPS!!',
            content: 'Erro: A página Web não encontrado',
            hreflang: 'pt'
        },
        {
            slug: 'de',
            name: 'Deutsch',
            title: 'OOPS!!',
            content: 'Fehler: Seite nicht gefunden',
            hreflang: 'de'
        },
        {
            slug: 'el',
            name: 'ελληνικά',
            title: 'Ωχ!!',
            content: 'Σφάλμα: Η σελίδα δεν βρέθηκε',
            hreflang: 'el'
        },
        {
            slug: 'en',
            name: 'English',
            title: 'OOPS!!',
            content: `I can't seem to find the page you are looking for :-(`,
            hreflang: 'en'
        }
    ];
    return languageList;
}
