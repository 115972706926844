export default function svgUpArrow(): JSX.Element {
    return (
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1536 1536">
            <title>icon-circle-arrow-up2</title>
            <path
                d="M1284 767q0-27-18-45L904 360l-91-91q-18-18-45-18t-45 18l-91 91-362 362q-18 18-18 45t18 45l91 91q18 18 45 18t45-18l189-189v502q0 26 19 45t45 19h128q26 0 45-19t19-45V714l189 189q19 19 45 19t45-19l91-91q18-18 18-45zm252 1q0 209-103 385.5T1153.5 1433Q977 1536 768 1536t-385.5-103Q206 1330 103 1153.5T0 768q0-209 103-385.5T382.5 103Q559 0 768 0t385.5 103Q1330 206 1433 382.5T1536 768z"
                fill="#afacac"
            />
        </svg>
    );
}
