export default function svgLangHu(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
            <g fillRule="evenodd">
                <path fill="#fff" d="M512.004 511.985H0V0h512.004z" />
                <path fill="#388d00" d="M512.004 511.985H0V341.323h512.004z" />
                <path fill="#d43516" d="M512.004 170.8H0V.137h512.004z" />
            </g>
        </svg>
    );
}
