export default function svgLangGr(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
            <defs>
                <clipPath id="a">
                    <path fillOpacity=".67" d="M0 0h666v666H0z" />
                </clipPath>
            </defs>
            <g clipPath="url(#a)" transform="scale(.77)">
                <g fillRule="evenodd" strokeWidth="1pt">
                    <path fill="#0d5eaf" d="M0 0h999v74H0z" />
                    <path fill="#fff" d="M0 74h999v74H0z" />
                    <path fill="#0d5eaf" d="M0 148h999v74H0z" />
                    <path fill="#fff" d="M0 222h999v74H0z" />
                    <path fill="#0d5eaf" d="M0 296h999v74H0z" />
                    <path fill="#fff" d="M0 370h999v74H0z" />
                    <path fill="#0d5eaf" d="M0 444h999v74H0z" />
                    <path fill="#fff" d="M0 518h999v74H0z" />
                    <path fill="#0d5eaf" d="M0 592h999v74H0zM0 0h370v370H0z" />
                    <g fill="#fff">
                        <path d="M148 0h74v370h-74z" />
                        <path d="M0 148h370v74H0z" />
                    </g>
                </g>
            </g>
        </svg>
    );
}
