import FlagAr from '@components/partials/svg/flags/langAr';
import FlagDe from '@components/partials/svg/flags/langDe';
import FlagEn from '@components/partials/svg/flags/langEn';
import FlagEs from '@components/partials/svg/flags/langEs';
import FlagFr from '@components/partials/svg/flags/langFr';
import FlagGr from '@components/partials/svg/flags/langGr';
import FlagHu from '@components/partials/svg/flags/langHu';
import FlagIt from '@components/partials/svg/flags/langIt';
import FlagJp from '@components/partials/svg/flags/langJp';
import FlagNl from '@components/partials/svg/flags/langNl';
import FlagPl from '@components/partials/svg/flags/langPl';
import FlagPt from '@components/partials/svg/flags/langPt';
import FlagRo from '@components/partials/svg/flags/langRo';
import FlagRu from '@components/partials/svg/flags/langRu';
import FlagSv from '@components/partials/svg/flags/langSv';
import FlagTr from '@components/partials/svg/flags/langTr';

export function getFlag(language: string): JSX.Element {
    switch (language) {
        case 'ar':
            return <FlagAr />;
        case 'de':
            return <FlagDe />;
        case 'en':
            return <FlagEn />;
        case 'es':
            return <FlagEs />;
        case 'fr':
            return <FlagFr />;
        case 'el':
            return <FlagGr />;
        case 'hu':
            return <FlagHu />;
        case 'it':
            return <FlagIt />;
        case 'ja':
            return <FlagJp />;
        case 'nl':
            return <FlagNl />;
        case 'pl':
            return <FlagPl />;
        case 'pt':
            return <FlagPt />;
        case 'ro':
            return <FlagRo />;
        case 'ru':
            return <FlagRu />;
        case 'sv':
            return <FlagSv />;
        case 'tr':
            return <FlagTr />;
        default:
            return <></>;
    }
}
