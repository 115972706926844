export default function svgLangRu(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
            <g fillRule="evenodd" strokeWidth="1pt">
                <path fill="#fff" d="M0 0h512.005v512H0z" />
                <path fill="#0039a6" d="M0 170.667h512.005V512H0z" />
                <path fill="#d52b1e" d="M0 341.333h512.005V512H0z" />
            </g>
        </svg>
    );
}
