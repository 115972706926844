import { getLanguageList } from '@data/languageList';
import styles from '@styles/scss/navigation/langDropdown.module.scss';
import { getFlag } from 'lib/helper/flag';
import { ApiLanguageProps } from 'lib/types/item/apiLanguageType';
import { useRouter } from 'next/router';

export default function LangDropdown({
    isLang,
    homeSlug,
    languageList,
    is404Page
}: {
    isLang: boolean;
    homeSlug?: string;
    languageList: ApiLanguageProps | undefined;
    is404Page?: boolean;
}): JSX.Element {
    let langs;
    let slug: any;
    let staticLangList = false;

    if (!languageList) {
        langs = getLanguageList();
        slug = 'slug';
        staticLangList = true;
    } else {
        langs = languageList;
        slug = 'lang';
    }

    const currSlug = homeSlug ? homeSlug.substr(1) : 'en';
    const selectedIndex = langs.map((lang: any) => lang[slug]).indexOf(currSlug);
    const selectedLang = langs[selectedIndex === -1 ? 15 : selectedIndex];
    const router = useRouter();

    const handleLocale = (lang: string) => {
        if (is404Page) {
            router.push('/', '/', { locale: lang });
            return;
        }
        router.push(router.asPath, router.asPath, { locale: lang });
    };

    let activeLangs;
    if (!staticLangList) {
        activeLangs = langs.filter((lang: ApiLanguageProps) => lang.isReviewActive === true);
    }

    return (
        <div className={styles.container}>
            {getFlag(selectedLang[slug])}
            <div className={styles.langName}>{selectedLang.name}</div>
            {isLang && (langs[0]['isReviewActive'] === undefined || activeLangs.length > 1) && (
                <div className={styles.langDropDown}>
                    {langs.map(
                        (lang: any) =>
                            lang[slug] !== selectedLang[slug] &&
                            (lang['isReviewActive'] === undefined ||
                                lang['isReviewActive'] === true) && (
                                <div
                                    key={lang[slug]}
                                    className={styles.langDDItem}
                                    onClick={() => handleLocale(lang[slug])}
                                    aria-hidden="true">
                                    {getFlag(lang[slug])}
                                    <div className={styles.langDDItemName}>{lang.name}</div>
                                </div>
                            )
                    )}
                </div>
            )}
        </div>
    );
}
