export default function svgLangPl(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
            <g fillRule="evenodd">
                <path fill="#fff" d="M512 512H0V0h512z" />
                <path fill="#dc143c" d="M512 512H0V256h512z" />
            </g>
        </svg>
    );
}
