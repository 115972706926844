import CookieConsent from '@components/partials/common/cookieConsent';
import Gtm from '@components/partials/common/gtm';
import Meta from '@components/partials/common/meta';
import EmailSignUpPopUp from '@components/partials/emailSignUp/popUp';
import BackToTop from '@components/partials/navigation/backToTop';
import Head from 'next/head';

export default function Base({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <>
            <Head>
                <Gtm />
                <Meta />
                <link
                    rel="shortcut icon"
                    href="https://assets.myfavsexcams.xxx/images/mfsc-favicon-48x48.png"
                />
            </Head>
            {children}
            <CookieConsent />
            <EmailSignUpPopUp />
            <BackToTop />
        </>
    );
}
