export default function svgLangJp(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
            <defs>
                <clipPath id="a">
                    <path fillOpacity=".67" d="M177.17 0h708.66v708.66H177.17z" />
                </clipPath>
            </defs>
            <g
                fillRule="evenodd"
                clipPath="url(#a)"
                transform="translate(-128) scale(.7225)"
                strokeWidth="1pt">
                <path fill="#fff" d="M0 0h1063v708.66H0z" />
                <ellipse
                    cx="523.08"
                    rx="194.93"
                    cy="344.05"
                    transform="translate(-59.707 -34.52) scale(1.1302)"
                    ry="194.93"
                    fill="#d30000"
                />
            </g>
        </svg>
    );
}
