// to do api call to get all the category with related  sites from cms
export function getTosMenuList(): any {
    // Api will return an object as this;
    const data: any = {
        en: {
            TOS: {
                label: 'tos',
                title: 'TOS',
                tab: 'TOS'
            },
            Cookie: {
                label: 'Cookie',
                title: 'Cookies',
                tab: 'Cookie'
            },
            USC18: {
                label: 'Usc18',
                title: '2257',
                tab: 'USC18'
            },
            DMCA: {
                label: 'Dmca',
                title: 'DMCA',
                tab: 'DMCA'
            },
            privacy: {
                label: 'Privacy policy',
                title: 'Privacy Policy',
                tab: 'privacy'
            }
        }
    };

    return data;
}
