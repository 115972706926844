import { LanguageFile } from 'lib/types/item/languageFile';

export default function EmailCardFormSimple({
    email,
    signup
}: {
    email?: string;
    signup?: string;
}): JSX.Element {
    const myhtml = `
        <form
            class='campaigner-form'
            name='previewForm'
            method='post'
            action='https://trillium.emailcampaigns.net/CSB/Public/ProcessHostedForm.aspx'
            _lpchecked='1'>
            <input
                type='hidden'
                name='__VIEWSTATEGENERATOR'
                value='5523BD75'>
            <input
                name='7112518'
                type='email'
                class='form-email'
                required='required'
                placeholder='${email ? email : 'Your E-mail'}'>
            <button
                type='submit'
                name='SubmitButton'
                value='Next'
                onclick='KRAKEN.emailcamp.submitForm(event)'
                class='btn-new-btn'>
                ${signup ? signup : 'Sign Up!'}
            </button>
            <input
                type='hidden'
                name='7254031'
                class='form-exid'
                style='width:200px;'>
            <input
                type='hidden'
                name='FormInfo'
                value='d6d715a1-dcd6-4b27-adbd-26db02bb7207'>
        </form>
        <div
            class='msg-alert form-success exs-alert-success'
            style='display:none'>
            You are now signed up!
        </div>
        <div
            class='msg-alert form-failed exs-alert-danger'
            style='display:none'>
        </div>
    `;
    return (
        <div
            className="email-campaigner-container"
            dangerouslySetInnerHTML={{ __html: myhtml }}></div>
    );
}
