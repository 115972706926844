export default function svgLangNl(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
            <g fillRule="evenodd" strokeWidth="1pt" transform="scale(.48166 .71932)">
                <rect rx="0" ry="0" height="708.66" width="1063" fill="#fff" />
                <rect rx="0" ry="0" height="236.22" width="1063" y="475.56" fill="#21468b" />
                <path fill="#ae1c28" d="M0 0h1063v236.22H0z" />
            </g>
        </svg>
    );
}
