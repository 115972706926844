export default function svgLogoMascot(): JSX.Element {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            width="460"
            height="511"
            viewBox="0 0 460 511">
            <path d="M225 4.6c58.7 0 111.8 23.8 150.3 62.3 38.5 38.3 62.3 91.4 62.3 150 0 58.8-23.8 112-62.3 150.4-38.5 38.5-91.6 62.3-150.3 62.3s-112-23.8-150.4-62.3C36.2 329 12.4 276 12.4 217.2c0-58.7 23.8-111.8 62.2-150.3C113 28.2 166.3 4.4 225 4.4v.2zm0 50c-89.8 0-162.6 72.8-162.6 162.6S135.2 379.8 225 379.8 387.6 307 387.6 217.2 314.8 54.6 225 54.6z" />
            <path
                style={{ fill: '#F4CEAB' }}
                d="M247 145.6c.8 12.6 3 33.6 8.3 41 7.7 10.8-.6 15.6-.6 15.6l-9.8 7-24 10.8s-21 5.3-26.7-5l-2.6-4.5c-4.2-1.6-8.2-5.2-11.5-11.2-10.7-24.3-18.8-31.5-20.8-39-2.2-8.3-1.5-8.7.4-15.6-9.5-7-9.3-40-9.3-40-5.6 1.3 16.2-7.3 21.8-1.7 5.6 5.6 16.2-6.5 25.6-10 9.4-3.3 28.2 12 28 14.3-.6 2.3 3 21.2 2 19-.7-2.3-2 3 0 0s26.6-15.4 24.3 6c-.7 7-3 11-5.5 13.3h.4z"
            />
            <path d="M396.2 70.5c5-2.7 12.7 13.8 17.6 9 2.6-2.4 31 12 14.8 15 0 0-10 3-12.5 3-5.7 0-24-19-19.7-27h-.2z" />
            <path d="M387 16l.5-2.7c3-6.6 12.2-5.7 17 0 3.3 4 4.3 15.5 10.7 15.3 1.5-1.8 2.4-5.7 3.7-8.4 12-25 20-17.4 23-18 10.6-2.5 7.3 3.2 10 5.6.4.4 6.2 2 2 11.6 3 2.5 5 2.2 4.2 8.8-1 8.8-12.5 14-14.6 29-1.4 10.7-2.6 16.5-8 22.4-5.3 6-8.5 1.5-9.2 14.5 0 1.4 0 .8-.2 2-2 8-26.8-12-26.3-17 1.2-3 3.3-2.6 4.2-6.3 1.4-6-4.2-7.7-4.3-26.8 0-9.3-1.5-11-5-16.3-2.7-4-7.6-8.8-8-13.6l.3-.1z" />
            <path d="M385.6 15.8l.4-2.7.2-.2c.6-1.3 1.4-2.4 2.4-3.2 1.7-1.4 3.7-2 5.8-2.2 2 0 4.2.3 6.2 1.2 1.8 1 3.6 2.2 5 3.8 1.3 1.6 2.2 4 3.2 6.6 1.5 3.8 3 7.8 5.7 8.2.6-1 1-2.4 1.7-4 .4-1 .8-2.4 1.3-3.4C428-2 436-.4 440.3.6h1.4c9-2 9.7 1 10.5 4 .2 1 .4 1.6 1 2 1.4.8 6.2 3.3 2.7 12.3l.4.3c2.5 1.8 4 2.8 3.3 9-.5 4.2-3 7.5-6 11.3-3.5 4.5-7.5 9.7-8.6 17.7-.6 5.4-1.3 9.6-2.5 13.3s-3 6.8-5.7 10c-2 2-3.7 3-5 3.6-2.2 1.2-3.6 2-4 9.8v1.3l-.2.8v.3c-.4 1.7-1.5 2.7-3 3-1.4.3-3.2 0-5.2-1-1.8-.6-3.8-1.7-5.8-3-7.6-5-15.6-13.2-15.3-16.6v-.4c.8-1.7 1.7-2.5 2.6-3.2.7-.5 1.3-1 1.7-2.6.5-2.2 0-3.8-1-6-1.3-3.8-3.3-9.2-3.3-20.5 0-8.6-1.3-10.3-4.4-15l-.2-.5-2.2-3c-2.6-3.3-5.6-7.2-6-11.2V16l.1-.2zm3.4-2l-.4 2.3c.4 3.3 3 6.7 5.4 9.8l2.2 3 .3.5c3.6 5.2 5 7.2 5 16.6 0 10.7 2 15.7 3.2 19.2 1 3 1.7 5 1 8-.6 2.4-1.6 3.3-2.7 4.2-.5.5-1 1-1.5 2 .2 2.5 7.3 9 14 13.5l5.2 3c1.5.4 2.7.6 3.5.5.3 0 .6-.3.7-.8V94c.4-9.5 2.3-10.5 5.4-12.2 1.2-.6 2.6-1.4 4.2-3 2.5-3 4-5.6 5-9 1.2-3.3 1.8-7.4 2.5-12.7 1.3-8.7 5.6-14.3 9.3-19 2.7-3.5 5-6.5 5.4-10 .5-4.3-.4-5-2-6l-1.7-1.4-.8-.7.5-1c3.2-7.4 0-9-1-9.6-.3 0-.4-.2-.6-.4-1-1-1.3-2.3-1.6-3.5-.4-1.7-1-3.3-7-2-.8.3-1.6 0-2.6 0-3.5-.8-10-2.2-19.5 17.4l-1.2 3c-.7 2-1.4 4-2.6 5.4l-.4.5h-.7c-5.3.2-7.3-5-9.3-10-.8-2.5-1.7-4.7-2.7-6s-2.5-2.3-4-3-3.2-1-4.8-1c-1.4 0-2.8.6-4 1.5-.6.6-1 1.3-1.5 2l-.2.5zm64-7c.2 0 0 0 0-.2v.2z" />
            <path
                style={{ fill: '#FFFFFF' }}
                d="M412.7 31c-.5 4-1.6 5.3-1.6 10 2.5-5.2 2.3-11 7.3-11.8-.7-2.6-1.7-1.2 2-8.5 3.8-8 10.3-19.2 19-17.2-1.4 2-5 3.8-9.6 11-2.6 4.2-5 11.8-8 14.2l4 2.5c-1-4.4-1.6-4 2-11 3-5.8 8.8-13.7 14-16.5 4.2-2.2 8-1.3 8.4 4-6.3 4-5 1.2-12 10.5-6 8.3-4 10.2-9 15.2 2 1 2 1.8 3.4 3-1-11 14.8-31.5 20.7-26.5 2 2 1.8 6 0 9-1.7 2.5-2.6 1-6.3 6-3.6 5-4 8.2-6.3 12.7-1.8 3.6-.4 1-3.2 2.5 2 1.2 2.3 1.7 3.3 3.3.8-6.5 2.2-10.2 5.8-16.2 2.2-3.4 9-11 11-2.8 2 8-6.3 11.6-10.8 21.4-2 4-1.6 5.3-3.8 5.4h-.3c2 12.3-4 29.7-13.4 30-5 0-7-3-10-5.4 2 4.5 4.3 4.6 8.2 7.2 0 1.7-1 7-2.4 8-1.3 1.3-2 .8-5.7-1-2-1-4-2.3-6-3 3.4 4.4 11 4.8 12 7.8-3.3 2-11.3-3.6-14.2-5.7l-5.5-4.5c-2-2-4.3-3.3-3.2-6.8 1.8.8 1.7 1.5 3.5 2.8l-.7-1.5s0-.3-.2-.4l-1-1.7c1.4-2.6 1.6-2.6 2.5-6.3 3.5.8 3 2.2 8 2.8-1.6-1.2-3.6-1.4-5.5-3-1.7-1.6-2.7-3-3.7-5-8-16.3 2.3-23-10.4-39.5-1.7-2.4-5.8-7.3-5.6-10.8.4-5.4 12.3-10.2 18 4.8 2 5.2 2.4 7 6.3 10.6l-1 .4z"
            />
            <path d="M58.2 419.3c.7 2.8 4.4 18 5.8 21.7 1.6 4 11.6 8 14.6 10 8.5 5.7 7.5 8.6 13.6 7 1.4-.5-3-2-.8-4.3 3.6-3.4-7.7-25-9.5-30.2-3-8-5-5.4-14.3-8.3-5.6-2-6 3-9.6 4l.2.1z" />
            <path d="M5.4 429l-2.2 1.5c-4.4 6 .3 16.4 7.2 19.6 5 2.6 15.5-1 18 6.6-.8 2.5-3.8 5-5.6 7.6-16.5 24-6.5 30.5-5.8 34 2.5 13.4 6 7.4 9.3 9.5.5.3 4.4 6.4 11-2.4 3.4 2.6 4 5 9.4 1.5 7.2-4.6 6.8-20 19-28.3 8.7-6 13.2-9.7 16-18 2.8-8.6-2.4-10.7 8.6-16.6l1.5-1c6.3-5.5-22-26.3-26.2-23.8-2.2 2.6-.7 5-3.5 7.4-4.5 4-8.3-2-25 5.6-8 3.6-10 2.7-16.3.7-4.5-1.6-10.8-5-15.2-3.8l-.2-.1z" />
            <path d="M6 430.3l-1.7 1.3-.6 1c-1 2.3-1 5.2 0 8 1.2 2.7 3 5.4 5.5 7 .6.5 1.2 1 2 1.2 1.3.7 3.6.8 6 1 5 0 10.7.4 12.7 6.2v1c-1 1.8-2.4 3.5-4 5.2l-2 2.8c-13 18.8-9 26.2-6.7 30 .6 1 1 2 1.2 2.8 1.5 8 3 8 4.7 7.7 1.3 0 2.5-.2 4 .7l.5.5 1 1c1.2 1 3.6 1.5 7.3-3.5l1-1 1 .7c.8.3 1.4 1 2 1.3 1.6 1.3 2.5 2 6 0 2.6-2 4.3-5.8 6-10.2 2.8-6 5.7-13 13-18 4.3-3 7.5-5.4 10-8 2.4-2.5 4.2-5.4 5.5-9.4 1-2.6 1-4.5 1-6.2 0-4.2 0-6.7 8.3-11l.7-.5.4-.5c.5-.5.5-1.2.2-2-.5-1.5-1.7-3.3-3.4-5-1.8-2-4-4-6.3-6-6-5-12.8-9-14.8-8.5-.5 1-.7 2-1 2.7-.2 1.6-.5 3-2.5 4.8-2.4 2-4.4 2-7.5 2-3.5-.2-8.7-.3-18 4-8.4 3.6-10.7 2.8-16.8.8l-.6-.2-3.7-1.2c-3.6-1.5-7.6-3-10.4-2.4v-.1zm-3.6-1l2.2-1.6h.2l.2-.2c3.8-1.2 8.5.7 12.6 2.4 1.2.3 2.4 1 3.5 1.2h.7c5.4 2 7.4 2.6 14.6-.6 10-4.5 15.6-4.4 19.4-4.2 2.4 0 4 0 5.5-1.3 1.2-1 1.5-2 1.7-3 0-1.3.4-2.6 1.6-4l.2-.3.2-.2c2.7-1.5 11.2 3 18.4 8.7 2.5 2 4.8 4 6.6 6.2 2 2 3.4 4.2 4 6 .8 2 .5 4-1 5.3l-.8.7c-.2 0-.3 0-.4.2l-.6.2c-6.6 3.5-6.7 5.4-6.8 8.5 0 1.8 0 4-1 7-1.5 4.5-3.5 7.7-6.2 10.6-2.7 3-6 5.3-10.5 8.3-6.5 4.4-9.2 11-11.6 17-2 4.8-4 9-7.4 11.4-5.2 3.4-6.8 2-9.5 0 0-.3-.2-.4-.4-.6-4.8 5.8-8.6 4.5-10.7 3l-1.4-1.3c-.6-.4-1.3-.4-2-.3-3 .3-6 .5-8-10.2 0-.4-.4-1-.8-1.8-2.5-4.4-7.3-13 6.8-33.3l2.5-3c1-1.2 2.4-2.5 3-3.6-1.6-3.5-6-3.7-10-3.8-2.5-.2-5-.3-7-1.2L7.3 450c-2.8-2-5-5-6.4-8.4-1.2-3.5-1.4-7.2 0-10.3l1-1.7.2-.2h.2l.1-.1z" />
            <path
                style={{ fill: '#FFFFFF' }}
                d="M29.4 452.7c3.3-2.2 4-4 8-5.8-3.4 4.4-8.7 6.5-7 12.6-2.7.2-2-1.4-6.7 5.6C18.5 473 11.5 485 17 494c1-2 1.2-7 5.5-15.2 2.5-4.7 8-10.5 8.8-15l4 3.6c-4.4.6-4.3-.2-8.8 7-3.6 5.7-8 15.4-8.3 22.5 0 5.5 2.3 9.5 7 8 .8-9-1-6.3 4.2-18 4.5-10.2 7-8.7 9.2-16.6 1.6 2 2.4 1.6 4 2.7-10 3.5-21 29.7-14 34.5 2.7 1.2 6-.5 8-3.8 1.4-2.8-.5-3.3 2.3-9.5 2.6-6.3 5.2-8 8.2-12.5 2.4-3.4.6-.7 1-4.6 1.8 2 2.2 2 4 2.5-5.2 3.4-8 6.6-11.5 13.2-2 3.7-5.7 14.4 2.2 13.6 7.7-.8 7.3-11.8 14-21 2.5-3.6 4-4 3-6.5v-.4c11.4-3 24-16.4 20-27.5-2-6-5.5-7-9-9.4 4.8.4 6 3 10 6.5 1.4-.7 5.4-4 6-6s-.3-2.3-3.5-6c-1.7-2-3.7-3.6-5.3-6 5.4 2.3 9 11 12 11 .3-4.7-8-12-11-14.3-2.3-1.8-4.2-3.2-6.4-4.6-2.6-1.5-4.7-3.4-7.3-1 1.4 2 2.3-2 4.2-.4l-1.7-.2c-.3-.2-5.7 7.4-6.6 8.2 2 3.7 3.2 2.8 5.7 8-1.4-1.2-2.5-3.5-4.7-5-2-1.4-3.8-2-6-2.3-17.5-2.7-19 11.8-38.8 3.6-3-1.3-9-4-12-2.4-4.4 2.5-3.4 18.2 12 18.8 5.4.3 7.3 0 12 3.2z"
            />
            <path d="M248 144.6c.7 12.4 3 34 8 41.4 8.2 11.4-.2 16.7-.7 17l-9.8 7h-.2l-24 11h-.2s-21.4 5.4-27.4-5.5c-1.3-2.5-2.5-4.4-3.5-5.8 1 .6 2.3 1 3.4 1.3l2 3.5c5 9.2 23.8 5 25.3 4.6l23.8-10.6 9.8-7s7.3-4.2.3-14.2c-5.3-7.3-7.6-28-8.5-40.6.8-.5 1.4-1 2-1.8l-.3-.3zm-63.4 60.8h-1l.4-.6.6.6z" />
            <path d="M151.7 105c0 3 .2 32.4 8.8 39l.6.3v.6l-.6 2c-1.4 4.7-1.8 6 .2 13 .8 3 2.8 6.2 5.6 10.7 3.8 6 9 14.2 15.2 28 2 4 4.6 6.7 7 8.6 3.2 2.3 6.4 3.3 9.7 3.2 3.4 0 6.8-1 10-3 5.4-3 10.5-7.8 14.8-13.6l5-6.5c6.5-8 11.4-14 9-24 .7 0 1.4-.2 2-.4 2.7 11-2.6 17.4-9.4 25.7-1.6 2-3.3 4-5 6.4-4.5 6-9.7 11-15.2 14-3.6 2.3-7.3 3.5-11 3.5-3.8 0-7.5-1-11-3.6-2.7-2-5.3-5-7.6-9.3-6-13.8-11.3-22-15-28-3-4.6-5-7.8-5.8-11-2-8-1.8-9-.2-14.3 0-.5.2-1 .4-1.5-8.3-7.4-9-33.5-9-39-2.7-.5-2.4-1.5.4-2.7.7-.3 1.8-.7 3.2-1 5.7-2 16-4 19.8 0 3 3 8.5-.6 14.2-4.4 3.4-2.4 7-4.7 10.4-6 1.7-.6 3.7-.6 6-.3 4.7 1 10 3.8 14.6 7 4.4 3 7.8 6.4 8.4 7.8v1c0 1 1 7.6 1.7 12.6l.5 4c2.2-1.4 6-3.4 10-4.4 2.5-.6 5-1 7.2-.5 2.3.5 4.3 1.5 5.6 3.6 1.4 2 2 5.3 1.6 9.8-1.3 12.4-7 15.6-11.8 17.2-.6-.6-1.4-1-2-1.4 4.5-1.4 10.5-3.6 11.8-16 .5-4 0-6.8-1.2-8.5-1-1.5-2.6-2.3-4.4-2.6-1.8-.2-4 0-6.2.5-5 1.3-9.8 4-10.6 5.2 0 .3-.2.5-.4.6-.8 1-1.5 1-2 .6s-.6-1.3-.4-2c.2-.5.6-1 1.2-1.3h.6c-.4-1-1.2-2.4-1.5-4.3-.8-5-1.8-11.6-1.6-13.2-.5-1.2-3.6-4-7.7-7-4.2-3-9.4-5.7-14-6.5-1.7-.3-3.4-.3-4.8.2-3.2 1-6.6 3.4-10 5.6-6.3 4.2-12.4 8.2-16.6 4-3.3-3-12.6-1.2-18 .5l-2.2 1-.3.1z" />
            <path d="M159 150.7v-2.5c-.2-9.5-6.3-13.8 10.4-10 4.6 1.2 6.3 1.8 7.4 6.6l.2 1.6c1.8 17.2-1.3 13-2.5 19.4-1 3.8 2.2 6.3 4.8 7.8 4.4 2.6 12 1.2 13.4.3-3.3 0-5.5-.3-9-.4-1 0-2.8-.5-3.6-1.2-2-2-4.3-3.8-2.7-7 2.4-4.8 1.5-11.6 1.2-17.5-.4-7-2.5-10-8.6-13-9.3-4.5-9-3.7-15-4-1.7-10-2.3-16.5-2.3-21l-1.8.5s1.2 20.8 3.4 26c2.2 5 3.7 6.5 4.7 14v.4zm56.2 19.5c-9.7 3.6-19.2 7.7-29.8 8.8-3.2.3-6.6-.7-9.7 0 1.4 1.5 3 2 4.7 3.6 5.4 5.5 7.5 18 18.6 15 11.2-3 12-16.6 17.6-26.4 1-.6 1-.5 2.3 1.2-1-3.3-1-2.4-3.7-4.3l-.2 2.3.2-.2z" />
            <path d="M215.2 170.3l-4 1.6c-8.3 3-16.6 6-25.8 7H181c-1.7-.3-3.5-.4-5 0l2 1.6c.8.5 1.6 1 2.4 2 1.7 1.5 3 3.7 4.3 6 3.2 5.3 6.5 10.8 14.3 8.7 7.8-2 10.5-9.4 13.4-17 1.2-3 2.4-6.3 4-9.2 1-.6 1.2-.5 2.3.8-.6-2-1-2.2-1.8-2.7-.6 0-1-.4-1.7-1v2h-.2l.2.2zm-4 1.4l4-1.5V168h.2c.7.6 1.2 1 1.6 1 1 .7 1.3 1 2 3.4l.2.5-.3-.6-.4-.3c-1-1.2-1.2-1.3-2-1-1.7 3-3 6.3-4 9.5-3 7.6-5.8 15-13.7 17-8 2.2-11.3-3.4-14.6-9-1.3-2-2.6-4.3-4.2-6l-2-1.5c-1-.5-1.7-1-2.4-1.8 1.8-.5 3.6-.4 5.4-.2 1.5 0 3 .2 4.4 0 9-.8 17.4-4 25.7-7l.1-.3z" />
            <path
                style={{ fill: '#FFFAF0' }}
                d="M182.4 180.7c2.8 6.5 11 4.6 16.8 2.5 6.5-2.4 11-4.4 13.6-10.5-9.5 3.3-20 9-30.4 8z"
            />
            <path d="M160.3 145c-3 0 3 2.6 0 1.2 1.2-3 2-3 5.3-3.6.7 7.5 6 6.2 7.2.7l3 1.2c-1.4-2.8-5-4-8.2-3.8-6 .2-5.6 1.5-9.4 4.3 1.2 1.6.8 2.6 2.2 0h-.1z" />
            <path
                style={{ fill: '#FFFAF0' }}
                d="M177.3 145.3l-4-2.6c-1.2 5.5-7 7.4-7.7 0-3.3.5-4 .4-5.3 3.5 9 4.3 9.2 3.5 17-1v.1z"
            />
            <path d="M176.3 145.3l-1.6-1-1.2-.8c-.5 1.8-1.5 3.2-2.7 4-.6.3-1.2.5-2 .5-.6 0-1.2-.2-1.8-.7-1-.7-1.6-2-1.8-4h-.2c-2.4.4-3 .5-4 2.6 7.7 3.5 8.5 3 14.4-.3l1-.5-.1.2zm-1-2l2.2 1.6.4.5-.6.2-1.6 1c-6.5 3.5-7.3 4-16-.2l-.3-.2.2-.4c1-3 1.8-3.3 4.8-3.8l.7-.2h.5v.6c.3 2 1 3.3 1.7 4 .3.3.8.4 1.2.4s.8 0 1.3-.4c1-.7 2-2.2 2.3-4l.2-.8.5.4 1.8 1.2.7.1zm22 26.2c10.2 1.8 8-8.7 2.6-8.2 5.3 4.8.7 7-3 8.2h.4zm12-27.8l-11 2c5.5 1.7 7.6-1 11-2z" />
            <path
                style={{ fill: '#ED1C24' }}
                d="M191.3 194c10 6.6 15-2.7 17.4-9.2 0 0-12.6 2.8-17.4 9v.2z"
            />
            <path d="M191 169.5c-1.7 0 2.2-3.8 5-4.8 2.7-1 2.5 2 2.5 2l-7.5 2.8z" />
            <path d="M191 169.7h-.5c-.2-.3-.2-.6 0-1 0-.2.2-.5.5-.8 1-1.4 3.2-3 5-3.7 3-1 2.8 2.3 2.8 2.3v.2h-.2l-7.5 2.7-.1.3zm-.2-.5l7.5-2.7c0-.6-.2-2.4-2.2-1.6-1.5.4-3.5 2-4.5 3l-.5 1h-.2l-.1.3zm42.5-26.2c.4-5.5 3.2-23.2 13.6-19.7 2.2.8 3 3.2 2 5.4-.3-1.3 0-2.7-1.3-3.6-7.2-5.6-14 16-14.5 18l.2-.1z" />
            <path
                style={{ fill: '#170000' }}
                d="M175.7 149.5c-4 1.5-8 2-11 1.6 6.4 1.8 8.3-.5 11-1.4v-.2zm.6 32.7c-3.7-2.4-.6-5.5 1.5-5.6-1.3 1.2-2.7 4.6-1.5 5.6z"
            />
            <path
                style={{ fill: '#3E3E3E' }}
                d="M233 214.6s4.2-22.3.6-25.2c-3.6-2.9 26.2-13.5 30.7-17.3s8.3 31 8.3 31 48.6-23 56.8-22.4c18.8-13.5 35.7-25.5 39-30.4 2.8-12 10.2-45 28-76.8 7 5.3 22.4 24.8 35.3 20.3-7 45.8-18.2 70.7-22.7 78.4-12.7 22-26.8 32-39.6 51.5-6.2 9.6-28 32.7-40.4 46 1.5 24 7 53.8 11 69.2-29.3 29.5-84.6 52-113.2 46.3s-30.2-3.5-43-6.8c-2-14.6-6-35-10-49.4l-3.4-7.6c-11 25.4-26.2 56-27 57.4-6 9-47.2 83.4-54 77.7-6-5.2-.7-24.2-28.2-36.5l-.8-.7c7.3-8.7 8-15.4 16.3-24.3 16.5-18 14.3-21 24.3-42 5-16.8 6.6-33 12.2-51.8 1-24.3.4-62.4 14.8-62.5h2.7c.6 0 1.2-.3 1.7-.4L130 233l50.4-10.2L172 200c-.2-.6 5.3-1.4 9.4-2 14.3 23.7 23.4 8.2 23.8 14.4 1 14.5 14.3 6.6 14.2 8-.6 6.5 13.5-5.8 13.5-5.8h.1z"
            />
            <path
                style={{ fill: '#E06939' }}
                d="M208 220.2l-3-10c-2.6.8-5.7 1.4-8.3 1 1.4 5.7 3 11.6 3.3 10.6 4-1.4 8-1.6 8-1.6zM181 197l-9 2.6s1.3 8.6 2.8 8l10.5-3.5c-1.2-1.3-2.4-3-3.5-5l-.8-2v-.1z"
            />
            <path d="M206 219l-2-7-3.2.7h-2.2c.6 2.3 1 4.4 1.6 6 0 0-.3 1-.7 1.7 2.4-1 5-1.3 6.5-1.5v.1zm.5-9.3l3 10 .4 2h-2s-3.5.2-7 1.3c-1.3 2.7-2.5.8-3.8-3.5-.6-2-1.4-5-2-8l-.6-2 2.2.2c1 .2 2.2.2 3.4 0 1.4-.2 3-.5 4.2-1l1.5-.5.4 1.5h.3zm-26.4-11l-6.4 2 .8 5.5 8.5-3-.6-.7-1.8-3-.4-.7-.1-.1zm-8.4-.6l9-2.4 1.3-.3.4 1.2.4 1 .4.8 1.7 2.6c.5 1 1 1.5 1.7 2.2l1.4 2-2.2.5c-4.6 1.4-8.6 3-10.2 3.5h-.2c-3.2 1.2-5-9.2-5-9.2v-1.3l1.2-.4.1-.2z" />
            <ellipse style={{ fill: '#E06939' }} cx="231.7" cy="245" rx="7.2" ry="7.4" />
            <path d="M231.7 236c2.4 0 4.6 1 6.2 2.7 1.3 1.6 2.3 3.8 2.3 6.3s-1 4.7-2.5 6.3c-1.7 1.6-4 2.6-6.3 2.6-2.4 0-4.6-1-6.2-2.8-1.5-1.6-2.5-4-2.5-6.3s1-4.8 2.5-6.4c1.6-1.7 3.8-2.7 6.2-2.7l.3.3zm4 4.8c-1-1-2.4-1.8-4-1.8s-3 .7-4 1.8c-1 1-1.7 2.5-1.7 4.2 0 1.6.6 3 1.7 4.2 1 1 2.4 1.7 4 1.7s3-.8 4-2c1-1 1.7-2.5 1.7-4 0-1.8-.6-3.3-1.7-4.3v.2z" />
            <ellipse style={{ fill: '#E06939' }} cx="240.3" cy="278.7" rx="7.2" ry="7.4" />
            <path d="M240.3 269.8c2.4 0 4.6 1 6.2 2.6 1.6 1.6 2.5 4 2.5 6.3s-1 4.7-2.5 6.3c-1.6 1.7-3.8 2.7-6.2 2.7-2.4 0-4.6-1-6-2.7-1.7-1.6-2.7-3.8-2.7-6.3s1-4.7 2.6-6.3c1.5-1.6 3.7-2.6 6.1-2.6zm4 4.7c-1-1-2.4-1.7-4-1.7s-3 .6-4 1.7c-1 1-1.7 2.6-1.7 4.2 0 1.7.7 3.2 1.7 4.2s2.4 1.6 4 1.6 3-.7 4-1.8c1-1 1.7-2.6 1.7-4.3 0-1.6-.6-3-1.7-4.2v.3z" />
            <ellipse style={{ fill: '#E06939' }} cx="248.7" cy="311" rx="7.2" ry="7.4" />
            <path d="M248.7 302c2.4 0 4.5 1 6 2.7 1.7 1.6 2.7 3.8 2.7 6.3s-1 4.7-2.6 6.3c-1.6 1.6-3.7 2.6-6 2.6-2.6 0-4.7-1-6.3-2.8-1.6-1.6-2.6-4-2.6-6.3s1-4.8 2.4-6.4c1.6-1.7 3.7-2.7 6.2-2.7l.2.3zm4 4.8c-1-1-2.5-1.8-4-1.8s-3 .7-4 1.8c-1 1-1.8 2.5-1.8 4.2s.5 3 1.5 4.2c1 1 2.5 1.7 4 1.7s3-.8 4-2c1-1 1.8-2.5 1.8-4s-.7-3.3-1.7-4.3l.2.2zM58.7 447c.6 2.8-4 5.5-4.3 13.6 0 3 .6 3.5-.3 5.6-4-11 .5-12.6 5-19.2h-.4zm307-292c1.2-2 6-7 9-5.4-2.4 2.2-6 4.2-9 5.4zM99 357.4c8-8.3 3.7-5 12.7-10.5-7.2 7.6-3 4-12.8 10.3l.1.2z" />
            <path
                style={{ fill: '#1A1A1A' }}
                d="M87.7 447.2s25.4-41 53.6-92.6c12.3-22.5 10.4-65.2 10.4-74.3 6 12.6 17 41.6 17.6 42.4-11 25-25.5 54-26.3 55.4-5.6 8.5-40 70.7-52.5 77-1.3-.7-2.3-4.5-2.8-7.7v-.2z"
            />
            <path d="M231.5 213.8c.6-3.3 3.8-21 1-23.2-1-1-1-2.2 0-3.5 2-2.4 10.5-6.2 18.2-9.6 5.8-2.6 11-5 12.7-6.3 5.7-4.7 9.4 22 10.4 29.8 11-5.2 66-30.4 57.6-17.4-.7 1-1.3 4.3-1.7 9-1-.3-2-.3-2.8 0 .3-5.5 1-9.3 2-10.6 3.4-5.6-55.7 22.5-56 22.5l-2 1v-2c0-.2-3.5-32-6-30-1.7 1.7-7.2 4-13 6.8-7.3 3.3-15 6.8-17 8.8 3.4 4.8-.7 26-.7 26.2v.4l-.5.3c-.2 0-14.8 13-16 6.3-1.2.3-2.7.7-4.4.8-4.3.3-9.3-1.2-10-10.3 0-.2.5-.2.3-.2-.4 0-1 0-1.4.3-4.2 1-12 3-22-13h-.5l-6 1.2 7.8 21.5.6 1.6-1.7.2L132 234l2 4c-.6 0-1.7.3-3 .7l-2.4-5.2-.8-1.7 2-.4 48.6-9.7-7.8-21.2c-.4-1 .2-1.8 1.4-2.3 1.4-.5 4.7-1 7.7-1.4l1.5-.3h1l.5.7c9 15 15.6 13.4 19 12.5l2-.4c1.8 0 3 .5 3 3 .5 6.6 3.8 7.7 6.7 7.5 1.5 0 2.8-.5 4-.7l2-.2c1 0 1.6.7 1.5 1.8-.4 2.5 8.4-5 10.4-6.7l.2-.2zm-81.7 58.7c-1 .4-2 .6-3 .7l25.7 56 3 11.5c3.4 11.5 7 26.3 8 37.7l.6 6c67.6 10.7 95 6.7 155-39l3.7-2.8-2.7-4c-3.7-16.5-8.6-49.6-10-79-.5-8.7-.8-17.3-1-25.5-1 1.7-2 3-3 4l1 22c1.4 29 5 62 9.8 78.6-14.6 13.6-31.7 24.6-50.5 32C268 378 247.8 382 226.8 382c-7 0-14-.5-20.8-1.4-6.6-1-13-2-19.4-3.7-1.3-11.5-5-26-8-37l-3.2-11.7v-.3l-25.6-55.4z" />
            <path d="M188 199c3 2.6 6.5 4.2 8.7 2 1.5-1.4 2.5-3.7 3.3-3.8 2.3 0 7 7.5 12.3-2.8 6-11.6 5.7-18 5.3-21.3-9.3-11-11.4 7-24.6 1 .5 8.5-26-1-14 11.4 4.2 4.6 6.5 9.6 9 13.7v-.2zm-35.4-88.8c-10 12.3-24.3.3-21.6 0 .3 0 11.4 5.8 11.2-7.7-.2-13.4 25-39.7 44.6-31 12.8 6 42.7.5 54 15.5 11 15 7.3 34.5 7.3 34.5s-10-4.6-15.6 11.3c-1.2 2.5-2 7.8-.2 10.8 3.6 6.5 9.4 22.4 7 29.7-1 3.3-2.3 6-5.2 10-2.8 3.7-7.4 9-9.4 12.2-4 7.2-5.5 9.5-13.4 13.6-19.6 10-28.4-2-36-18.4-16.7-21.2 10.7-14.5 18-16.7 11.6-3 18.8-14 27-7.5.4 0 .8 0 1-.2 1.4.6 2.7 1.7 4 3.2 2.5-1.5 7.2-6.7 3.7-21.3-.6-2-2-5.7-3-10l-.3-.7c-1.5-8.5-2.7-18.6-2.6-21.8 0-1.3-2-7.5-2.5-8.4-.5-.4-.8-1.4-1.6-1.6-15-4-24.4 1.5-34.4 4.2-8 2-10 1-16.5-.3-5.7-1-12-3-15.3.8l-.2-.2zm66.5 88.3c0 .4 0 .7-.3 1l-1 .7 1.5-1.7h-.2z" />
            <path
                style={{ fill: '#E06939' }}
                d="M182.4 226s-2-10-7.2-7c-5 3-56 8.3-56 8.3l3.7 11.4 59.3-12.8.2.1z"
            />
            <path d="M180.6 224.8c-.7-2.2-2.3-5.8-4.7-4.4-5 2.8-45.7 7.2-55 8.2l3 8.4 56.5-12.2h.2zm-6.2-7c7-4 9.5 7.7 9.5 7.8v1.5l-1.4.5-59.4 12.8-1.4.3-.5-1.3-3.7-11.4-.6-2h2s50.4-5.3 55.2-8l.3-.2z" />
            <path
                style={{ fill: '#1A1A1A' }}
                d="M330.3 265.8c12.4-13.2 33-34 39.3-43.7 12.8-19.4 26-29 38.8-51 4-6.6 14.7-38 22.7-76h-8s-18 60.7-21.6 69.7S329 235 329 235l1.3 30.8z"
            />
            <path
                style={{ fill: '#E06939' }}
                d="M388.8 92.7c1-2 2.4-5.7 3.4-7.5 4.6 8.6 22.7 22.7 35.7 22-.6 3.5-.8 4.6-2 9-31-2.8-38.5-23.5-37.3-23.5h.2z"
            />
            <path d="M386.4 91l1-2.5 2-4.7 2.7-5 3 5c2.2 4.3 8.4 10.2 15.6 14.7 5.8 3.5 12 6 17.3 5.7l3.5-.2-.5 3.6-.8 4.4-1.4 5.2-.7 2.3-2.2-.2c-22-2-33-12.8-37.3-19.7-1-1.5-1.6-3-2-4-.7-1.5-.8-3-.5-3.6 0-.5.2-.8.4-1h-.1zm5.8-5.8c-1 1.8-2.4 5.5-3.4 7.5-1.2 0 6.3 20.7 37 23.6 1.5-4.5 1.7-5.6 2-9-13 .6-31-13.5-35.6-22.1z" />
            <path d="M430 95.5c-10.4 1.6-21.4-9-29-16.2l-4-3.8c-16.2 29.4-23.5 59.5-26.7 72.7l-.5 2.2v.3l-.2.2c-3.2 4.7-18.7 16-36.4 28.5l-2.6 2c.2-1.3-1.4-1.4-4.2-.8l5-3.6c17.2-12.2 32.3-23 35.5-27.7l.3-2c3.3-13.5 10.8-44.7 27.7-75l1-1.5 1.3 1c1.6 1.2 3.5 3 5.7 5.2 7.7 7.3 19 18 28.2 15l2.4-1-.4 2.5c-2.8 18.3-6.2 33.2-9.7 45-5.2 18.2-10.5 29.2-13.2 34-7 12.2-14.5 20.7-22 29-6 7-12 13.8-17.6 22.5-6.3 9.6-27.6 32.3-40 45.7-.6.4-1 1-1.6 1.3v-4c12.5-13.3 33-35.4 39.3-44.7 5.8-9 11.8-15.8 18-22.7 7.2-8.5 14.6-16.8 21.5-28.8 2.7-4.6 7.8-15.4 13-33.2 3.2-11.3 6.5-25.4 9.2-42.5v.4z" />
            <path
                style={{ fill: '#E06939' }}
                d="M272.2 208.5s-2.8-11 3.4-11c6.2 0 55.8-21.8 55.8-21.8l2.2 13.6-61.4 19.2z"
            />
            <path d="M270.8 208.8s-3.3-13 4.8-12.7c6 .5 55-21.5 55.2-21.6l1.8-.7.3 2 2 13.4.2 1.4-1.2.4-61.3 19.2-1.5.4-.4-1.6.1-.2zm4.7-9.7c-3 0-2.6 5-2 7.7l58.4-18.4-2-10.3c-9 3.8-49 21.4-54.7 21h.3zm-213 219.7c19 9 22.5 21 24.7 29.2 1 3 1.7 6 3.2 7 .6.5 2.7-1.6 6-5 10.4-12.7 28-42.6 38.2-60l7.4-12.3c1-1.4 16.5-33 27.6-58.5l1.7 3.7c-11 25-26 55-26.8 56.3-1.2 1.8-3.8 6.3-7.3 12.2-10.3 17.5-28 47.6-38.6 60.2-4.4 5-6.8 7.6-9 5.8-2.2-2-4.2-5-5.2-9-2.2-7.8-5.3-19.2-23.8-27.5h-.4l-.8-.7-1-1 .8-1.2c3.3-3.7 5.2-7.3 7-11 2.5-4 4.8-8.4 9.4-13.3 12-13 14-17.7 18.2-27.8 1.5-3.7 3.2-8 5.8-13.5 2.4-8 4-16 5.7-24.2 1.8-8.7 3.6-17.7 6.4-27.3 0-2.7.2-5.3.3-8 .8-24.5 1.7-55.7 16-55.7h4l.4 1c-2 .5-5.5 1.6-7.3 2.7-8.4 4.7-9.3 31-10 52 0 3 0 5.7-.2 8.2v.4c-3 9.6-4.7 18.5-6.5 27.2-1.7 8.3-3.3 16.4-5.7 24.6v.2c-2.7 5.5-4.5 9.7-6 13.3-4.3 10.2-6.3 15.3-18.7 28.6-4.5 4.6-6.7 8.7-9 12.8-1.7 3.3-3.5 6.6-6.3 10.2l-.2.4z" />
            <path
                style={{ fill: '#E06939' }}
                d="M100 444.5c-1-11.7-15-32.2-29.8-37.5 2.2-3.7 2.8-5 6-9.5 29.5 16 33 32.7 32.2 35.6-1.7 3-4.2 7-8.4 11.6v-.2z"
            />
            <path d="M97 444.8c-.5-5.7-4.5-13.7-10-21-5-6.2-11.4-11.7-17.8-14l-3.4-1.3 2-3 1-2c1.4-2.4 2.3-4 5-7.7l1.6-2 2.3 1c11.4 6.3 19 12.6 24 18.2 9 10 10.2 18.6 9.6 20.8v.4l-.3.4-3.4 5.3c-1.5 2-3.2 4-5.4 6.4l-4.6 5-.6-6.7v.2zm3-.3c4.2-4.6 6.7-8.5 8.4-11.4.7-2.7-2.7-19.3-32-35.4-3.4 4.6-4 5.8-6.2 9.5C85 412.4 99 433 100 444.6v-.1z" />
            <path
                style={{ fill: '#E06939' }}
                d="M238.6 382.3L193 223s39.7-7 40.4-11c.6-3.8-.8-24.6-.8-24.6l32.8-15.5c1-.5 2.4 4 3.5 9.4-4 3.6-27.3 7.4-26.8 11.4s1 16 1 24c.3 7.4-38.5 11-37.3 15.3l40.6 149.3c-2.6.4-5.3.6-8 .8l.2.2z"
            />
            <path d="M237 382.6l-45.4-159-.4-1.7 1.6-.5s38.6-7 39-9.7c.7-4-.7-24.4-.7-24.5v-1l1-.4 33-15.5h.3c.8-.3 1.6.2 2.2 1 .4.6.7 1.4 1 2.3.7 1.7 1.4 4.3 2 7.2v.8l-.5.5c-2 2-7.6 3.6-13.2 5.3-6.5 2-13 4-13 5l.6 9.6c.3 4.7.4 10.3.5 14.7 0 5.3-12.8 8.4-23.8 11l-7.3 2c-4 1-6.7 1.7-6.7 2L248 381l.5 1.7-1.7.2-4 .2c-1.5 0-3 .3-4.2.4h-1.2l-.3-1-.1.1zm-42-158.3L240 382c1 0 2 0 3-.2l2.2-.3-40.6-148.8c-.7-2.4 3-4 8.6-5.6 2.2-.4 4.8-1 7.4-1.7 10-2.4 21.4-5.2 21.3-8 0-4.4-.4-10-.6-14.7l-.7-9.4c-.4-3.4 7.4-5.8 15-8 4.8-1.5 9.5-3 11.4-4.2-.4-2.3-1-4.4-1.5-6l-.5-1-30.8 14.5c.2 4.3 1.2 20.5.6 24-.7 4.4-32.3 10.6-39.8 12v-.3z" />
            <path
                style={{ fill: '#3E3E3E' }}
                d="M150.5 84c-1-4-11.5-32.5-11.5-32.5s42.5-40 111.4-2L240 87.2c-3-3.2-72.7-24.4-89.5-3.2z"
            />
            <path d="M149 84.3c-1-4-11.4-32.2-11.4-32.3l-.3-1 .7-.6S181.2 9.8 251 48l1 .7-.2 1.2-10.4 37.5-.7 2.6-1.8-2c-2.3-1.5-19-7-38-10-18.6-3-39-3.4-48 5.3l-1.5 1.5-2 2.3-.6-3 .2.2zM140.8 52c1.6 4.3 8 22 10.4 29 10-9.3 31-9 50.4-6 16.7 2.8 32 7.6 37.4 10l9.6-34.8c-62.2-33.6-102-3.2-107.8 1.8z" />
            <path d="M145.6 67.5s43-26.2 98.3 3.5l1 .6v1.2c0 3-3 15-3 15l-.8 2.4L239 89s-49.2-26.4-87.4-2.2l-2 1.4-1-2.4-4-16-.3-1.5 1.3-.8zm1 1.8s3.6 14.8 4 15.8c39-24.6 89.4 2.3 89.4 2.3s3-11.6 3-14.5c-54.3-29-96.4-3.5-96.4-3.5v-.1zm42.2 63.7c4.2.8 11.7-4.2 16.3-5.5 7-2 10.6-.2 16.7 0-14.2-11.5-35.8-3-32.8 5.4l-.2.1zm4.2 8.4c4.5-1 7-2.6 12-2.8 4.5 0 8 1.7 10.3-1.6-7-4.3-19.4 3-22.3 4.4z" />
            <path
                style={{ fill: '#9A9896' }}
                d="M220 108c-3.3-8.6-.5-11.7 4-13-2.5 1.7-6.5 4.3-4 13zm4-7c8 4.7 8.2 13.4 7.8 22v-.2.5c-.7-.5-1.7-14.4-8-22.4l.2.1zm5.2-2.8c6.2-1.7 11.7 8.6 11 15-2.3-6.3-4.7-12.3-11-15zm2.2-6.4c11.4 2 13.7 7 13 18.4-2.3-11-3-13.5-13-18.4zM218 89c-21-8.3-56-8-67.3 15.7l-2 5.3c1.5-6.7 2-9.3 7-14.7C171 78.8 199.7 79 218 89z"
            />
            <path
                style={{ fill: '#9A9896' }}
                d="M211.4 95c-19.6-1.6-15.4 12-45 6.8-.5 0-.2 0-.8-.2 2.7-1 9.2.3 9.3.3 16.2-.5 23.6-11.7 36.3-7l.2.1z"
            />
            <path d="M162 142c4-2.7 9.2-4.4 13.4-1.5-4.4.3-9 1-13.4 1.5zm31-3.3c2.2-10.4 17-7.8 17.7-7h-.5c-2 .5-2 .7-4 .6-7-.4-11.6 5.2-13 6.7-.4.5-.5.5-.2-.3zm47.3-12c-.3 3.6-1 6.8-1 10.8-3-1.3-1.6-10.3 1-10.8zM216.8 138c2.6.8 2.6 1.2 4.3 3H220c-2.5-.5-1.8-.7-3-3h-.2zm0-.6c2.4.8 3.4.2 6-.4l-1.4-.6c-2.3-1-2.5-.2-4.6 1z" />
            <path
                style={{ fill: '#E06939' }}
                d="M146.6 69.3s42-25.7 96.3 3.4c0 3-3 14.5-3 14.5s-50.4-27-89.6-2l-4-16 .3.1z"
            />
            <path
                style={{ opacity: '0.3', fill: '#FFFFFF' }}
                d="M214.4 78l8.6-38.7c5.4 1.6 11 3.7 16.7 6.3l-8.3 37.8c-5.5-2.2-11-4.2-17-5.5v.1z"
            />
            <path d="M377.7 365c-.8 1-1.6 1.7-2.4 2.5-38.5 38.5-91.6 62.3-150.3 62.3-26.3 0-51.5-4.8-74.7-13.5l23.2-44.8c16 5.4 33.5 8.3 51.5 8.3 49.3 0 93.5-22 123.3-56.6l29.4 41.8z" />
        </svg>
    );
}
