import EmailIcon from '@components/partials/svg/emailIcon';
import styles from '@styles/scss/emailSignUp/popUp.module.scss';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';

import EmailCardFormSimple from './cardFormSimple';

export default function EmailSignUpPopUp(): JSX.Element {
    const [isShown, setShown] = useState({ soft: false, hard: true });

    const hideForm = () => {
        setShown({ soft: false, hard: false });
        const date = new Date();
        date.setTime(+date + 1800000);
        document.cookie = 'hide_form=true;expires=' + date.toUTCString();
    };
    useEffect(() => {
        const cookies = parseCookies();
        if (cookies.hide_form === 'true') {
            setShown({ soft: false, hard: false });
        }
    }, []);

    const [scrollPos, setScrollPos] = useState(0);
    useEffect(() => {
        window.addEventListener('scroll', () => setScrollPos(document.documentElement.scrollTop));
    }, []);
    useEffect(() => {
        const finalThoughts = window.document.getElementById('final-thoughts');
        if (finalThoughts && scrollPos > finalThoughts.offsetTop + 1000) {
            setShown({ ...isShown, ...{ soft: true } });
        } else if (!finalThoughts && scrollPos > 0) {
            setShown({ ...isShown, ...{ soft: true } });
        }
    }, [scrollPos]);
    return (
        <>
            {isShown.soft && isShown.hard ? (
                <div className={styles.container}>
                    <button
                        className={styles.closeButton}
                        aria-hidden="true"
                        onClick={() => hideForm()}
                        onKeyDown={() => hideForm()}></button>
                    <div className={styles.emailIcon}>
                        <EmailIcon />
                    </div>
                    <div className={styles.form}>
                        <h4>
                            {`Get deals and FREE tokens & minutes for many of my fav cams sites - straight to your inbox!`}
                        </h4>
                        <EmailCardFormSimple />
                    </div>
                </div>
            ) : null}
        </>
    );
}
