export default function svgLangEn(): JSX.Element {
    return (
        <svg
            version="1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="450"
            height="450"
            viewBox="0 0 450 450">
            <defs>
                <path
                    id="a"
                    fill="#fff"
                    d="M0-30l17.634 54.27-46.166-33.54h57.064l-46.166 33.54z"
                />
                <g id="b">
                    <use xlinkHref="#a" transform="rotate(90 -13.5 53.5)" />
                    <use xlinkHref="#a" transform="rotate(90 25.5 92.5)" />
                    <use xlinkHref="#a" transform="rotate(90 64.5 131.5)" />
                </g>
                <g id="f">
                    <use xlinkHref="#b" y="-25" />
                    <use xlinkHref="#b" y="60" />
                    <use xlinkHref="#b" y="140" />
                </g>
                <clipPath id="e">
                    <path d="M0 0h450L0 450z" fill="#0D47A1" />
                </clipPath>
                <g id="c">
                    <path fill="#fff" d="M0-27h450v54H0z" />
                    <path fill="#D32F2F" d="M0-18h450V0H0z" />
                </g>
                <use xlinkHref="#c" transform="rotate(45 -159.1 384.1)" id="d" />
            </defs>
            <path d="M0 450L450 0v450z" fill="#0D47A1" />
            <use xlinkHref="#d" />
            <path fill="#fff" d="M0 180h450v90H0z" />
            <path fill="#fff" d="M180 0h90v450h-90z" />
            <path fill="#D32F2F" d="M0 198h450v54H0z" />
            <path fill="#D32F2F" d="M198 0h54v450h-54z" />
            <g clipPath="url(#e)">
                <path fill="#D32F2F" d="M0 0h450v450H0z" />
                <path
                    stroke="#FFF"
                    strokeWidth="50"
                    d="M0 75h1050M0 175h1050M0 275h1050M0 375h1050M0 475h1050M0 575h1050M0 675h1050"
                />
                <path fill="#0D47A1" d="M0 0h240v250H0z" />
                <use xlinkHref="#f" />
            </g>
            <path strokeWidth="24" stroke="#fff" d="M-5 470L482-17" />
        </svg>
    );
}
